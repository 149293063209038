import React, { useState, useEffect, useRef } from "react";

const CustomSelect = ({ key, name, placeholder, options, value, onChange,label }) => {
  const [isActive, setIsActive] = useState(false);
  const selectRef = useRef(null);

  const handleSelectClick = () => {
    setIsActive(!isActive);
  };

  const handleOptionClick = (...optionValue) => {
    setIsActive(false);
    onChange(...optionValue);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isActive]);

  return (
    <div className="select" ref={selectRef}>
      <div className={`form-select ${isActive ? "active" : ""}`} onClick={handleSelectClick}>
        {label?label:value ? value : placeholder}
      </div>
      <ul className={`options ${isActive ? "active" : ""}`}>
        {options &&
          options.map((option, index) => ( // Check if options is not undefined/null before mapping
            <li key={index} onClick={() => handleOptionClick(option.value,option.label)}>
              {option.label}
            </li>
          ))}
      </ul>
    </div>
  );
  
};

export default CustomSelect;