import React, { useState } from 'react';
import MyProfileDetails from './myProfileDetails';
import EditProfileForm from './editProfileForm';
import './myProfile.css';

const MyProfile = () => {

const [isEditing, setIsEditing] = useState(false);

const handleEditClick = () => {
  setIsEditing(true);
};

const handleCloseEditForm = () => {
  setIsEditing(false);
};
 
  return (
    <div className='nosidebar-page__main'>
      <div className="dashboard">

          {isEditing ? (
          <EditProfileForm onClose={handleCloseEditForm} />
        ) : (
          <MyProfileDetails onEditClick={handleEditClick} />
        )}

      </div>
    </div>
  );
};

export default MyProfile;
