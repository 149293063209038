import React, { useState, useEffect} from 'react';
import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './dashboard.css';
import apiService from '../../../services/apiService';
import moment from 'moment';
import {Link, useLocation} from 'react-router-dom'
import CustomToast from '../../Toast'
import {CSVLink} from 'react-csv';


const Attempts = ({ showDetails = true, attemptsProps, selectedOrgId}) => {
    const location = useLocation()
    attemptsProps = attemptsProps || location.state?.attemptsProps
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);
    const [activeFilterEndLine, setActiveFilterEndline] = useState('all');
    const [activeFilter, setActiveFilter] = useState('all');
    const [orgInfo, setOrgInfo] = useState()
    const [selectedOrg, setSelectedOrg] = useState()
    const [coursePerformance,setCoursePerformance]=useState([])
    const [data, setData] = useState()
    const [dataToDownload, setDataToDownload] = useState([]);
    const columns = [ // Define column names
      "S. No.",
      "Scores",
      "No Of Users"
    ];
    // if(activeFilter) {
    //     const newColumn = activeFilter == 'department' ? "Department" : activeFilter == 'gender' ? "Gender" : activeFilter == 'age_group' ? "Age Group" : null
    //     if(newColumn) {
    //         columns.push(newColumn)
    //     }
    // }

    useEffect(() => {
     window.scrollTo(0, 0);
      if(attemptsProps) {
        setDatas(attemptsProps);
        setCoursePerformance(attemptsProps)
        setOrgInfo(selectedOrgId)
      }
    }, [attemptsProps, activeFilter])

    useEffect(() => {
        if(Array.isArray(coursePerformance)) {
            const formattedData = coursePerformance && coursePerformance.length > 0 && activeFilter == 'all' ?  Object.entries(coursePerformance[0])?.map(([key, value], i) => [
                // Include necessary fields in the exported CSV
                (i+1),
                // row.date, // If you want to include the date
                value,
                (i+1)
              ]): [];
              setDataToDownload(formattedData);
        }
        // if(typeof coursePerformance == 'object') {
        //      const formattedData =  Object.values(coursePerformance).map((category, i) => {
        //         if(Array.isArray(category) && category.length > 0 ) {
        //             Object.entries(category[0]).map(([key, value], j) => {
        //                 (j+1),
        //                 (extractedNumberFromString(key)),
        //                 (value?.users),
        //                 (activeFilter)
        //             })
        //         }   
        //         })

        //     setDataToDownload(formattedData);    
        // }
      }, [coursePerformance]);

    const setDatas = (attemptsProps, filter) => {
        attemptsProps = Array.isArray(attemptsProps) ? attemptsProps : []
        if(attemptsProps && attemptsProps.length > 0) {
          const labels = Object.keys(attemptsProps[0])
            const datasets = [
              {
                  label: 'Count',
                  data: labels.map(section => attemptsProps.reduce((acc, obj) => acc + obj[section].users, 0)),
                  backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
                  barThickness: 50,
                }
            ]
            const mData = {
                labels: labels,
                datasets: datasets
            };
            setData(mData)
        }
    }

    const userCoursepAttempt=(orgId, start, end, filter)=>{
        let formattedStartDate = start ? moment(start).format('YYYY-MM-DD') : undefined;
        let formattedEndDate = end ? moment(end).format('YYYY-MM-DD') : undefined;
        const org_id = orgInfo || selectedOrgId
        const today = moment();
        const oneMonthAgo = moment(today).subtract(30, 'days');
        const defaultStartDate = moment(oneMonthAgo).format('YYYY-MM-DD');
        const defaultEndDate = moment(today).format('YYYY-MM-DD');
        const token = apiService.getAuthToken()
        apiService.getanalytics(`course-attempt-performance?org_id=${org_id||''}&startDate=${formattedStartDate || ''}&endDate=${formattedEndDate || ''}&filter=${filter || ''}&analytics_category=course_attempt&test_type:end_line`,token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                if(filter && filter != "department" || filter != "gender" || filter != "age_group" || filter !="all") {
                    setDatas(response?.data, filter)
                }
                setCoursePerformance(response?.data)
            }
        }).catch((error)=> {
            CustomToast.error('Something went wrong')
        })
    }

    const handleFilterClick = (filter) => {
      setActiveFilter((prevFilter) => {
        if (prevFilter !== filter) {
          userCoursepAttempt(orgInfo, null, null, filter);
        }
        return filter;
      });
    };

    const handleCourseSelect = (selectedOptions) => {
        setSelectedCourses(selectedOptions);
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                  label: function(tooltipItem) {
                    const dataPoint = tooltipItem.raw; 
                   // const label = tooltipItem.label; 
                    return ` ${dataPoint} ` + 'Users';
                },
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
               anchor: "middle",
               align: "middle",
               color: '#fff',
               font: {
                  weight: '600'
                },
               formatter: (value) =>  {
                if(value === 0) {
                    return ' ';
                }
                  return value;
               } 
             },
        },
      };

      const extractedNumberFromString = (inputString) => {
        const matchResult = inputString.match(/\d{1,2}/);
        if (matchResult !== null) {
          const extractedNumber = parseInt(matchResult[0], 10);
          return extractedNumber;
        } else {
          return 0
        }
      };

    return (
        <div className="page__main">
            <div className="dashboard">

                <h3 className="fw-bold font-20 mb-4">
                    {/* <a href='/attempts' className='text-black text-decoration-none'>Attempts</a> */}
                    <Link
                        to="/attempts"
                        state={{ showDetails: false, attemptsProps}}
                        className='text-decoration-none text-black'>
                            Attempts
                    </Link>
                </h3>
                <div className='statistics'>
                    {showDetails && (
                        <>
                        {/* <div className='mb-4 w-75'>
                            <Select
                                options={courseOptions}
                                isMulti
                                value={selectedCourses}
                                onChange={handleCourseSelect}
                                className='w-25 me-4'
                                placeholder="Select Course"
                            />
                        </div> */}
                   
                     <hr /> 
                     </>)}

                    {showDetails && (
                        <div className='filters mb-4'>
                            <a href="javascript:void(0)" className='fw-medium btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('all')}
                                style={{ color: activeFilter === 'all' ? '#146B90' : '#000000' }}
                            >
                                All
                            </a>
                            <a href="javascript:void(0)" className='fw-medium btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('department')}
                                style={{ color: activeFilter === 'department' ? '#146B90' : '#000000' }}
                            >
                                Department
                            </a>
                            <a href="javascript:void(0)" className='fw-medium btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('age_group')}
                                style={{ color: activeFilter === 'age_group' ? '#146B90' : '#000000' }}
                            >
                                Age
                            </a>
                            {/* <a href="javascript:void(0)" className='fw-medium btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('designation')}
                                style={{ color: activeFilter === 'designation' ? '#146B90' : '#000000' }}
                            >
                                Designation
                            </a> */}
                            <a href="javascript:void(0)" className='fw-medium btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('gender')}
                                style={{ color: activeFilter === 'gender' ? '#146B90' : '#000000' }}
                            >
                                Gender
                            </a>
                        </div>
                    )}


                    <div className="stats d-flex justify-content-between align-items-center">
                        {/* <div className="w-50 bg-white p-3 me-3">
                            <h3 className="fw-bold font-20 mb-4">Base Line</h3>
                            <hr />

                            <Doughnut        
                                data={dataByFilter[activeFilter]}
                            />

                        </div> */}
                         {console.log("attempt data=>", data)}
                        {data && Object.keys(data).length > 0 ? <div className="w-50 bg-white p-3">
                            <h3 className="fw-bold font-20 mb-4">End Line</h3>
                            <hr />
                            <Doughnut
                                data={data}
                                options={options}
                                plugins={[ChartDataLabels]}
                            />
                        </div> : null }
                    </div> </div>

                {showDetails && (
                    <>

                <div className="text-end my-4">
                    <CSVLink
                        data={[columns, ...dataToDownload]}
                        filename={`data.csv`}
                    >
                        <a className="btn btn-link export">CSV Export</a>
                    </CSVLink>
                </div>

                    <table className="table table-striped table-responsive mt-5">
                        <thead className="thead-dark">
                            <tr>
                                <th className="text-center w-10">S. No.</th>
                                <th>Scores</th>
                                <th>No. Of Users</th>
                                {activeFilter && activeFilter != "all" ? <th>{activeFilter == 'department' ? "Department" : activeFilter == 'gender' ? "Gender" : activeFilter == 'age_group' ? "Age Group" : null}</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                        {console.log("sasa", coursePerformance)}
                            {Array.isArray(coursePerformance) ? coursePerformance && coursePerformance.length > 0 && activeFilter === 'all' && Object.entries(coursePerformance[0]).map(([key, value], i) => (
                                <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{extractedNumberFromString(key)}</td>
                                <td>{value?.users}</td>
                                </tr>
                            )) : typeof coursePerformance == 'object' ? (
                                Object.values(coursePerformance).map((category, i) => (
                                Array.isArray(category) && category.length > 0 ? (
                                    Object.entries(category[0]).map(([key, value], j) => (
                                    <tr key={j}>
                                        <td>{j+1}</td>
                                        <td>{extractedNumberFromString(key)}</td>
                                        <td>{value?.users}</td>
                                        <td>{activeFilter}</td>
                                    </tr>
                                    ))
                                ) : null
                                ))
                            ): null
                            }
                        </tbody>
                    </table>
                    </>
                )}
            </div>
        </div>
    );
};

export default Attempts;
