import React, { useState, useEffect} from 'react';
import Chart from 'chart.js/auto';
import { Bar, Doughnut } from 'react-chartjs-2';
import apiService from '../../../services/apiService';
import { useParams } from 'react-router-dom';
import Loader from '../../layouts/loader';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CSVLink } from 'react-csv';

const Stats = () => {
     const [uCourseScore, setUCourseScore] = useState()
     const [userCoursepAttempts, setUserCoursepAttempt] = useState()
     const [orgUserTestCardData, setOrgUserTestCardData] = useState() 
     const [coursePerformances, setCoursePerformances] = useState()
    const [activeFilter, setActiveFilter] = useState('all');
    const { course_id, course_name, org_course_id} = useParams();
    const [coursePerformanceData, setCoursePerformanceData] = useState()
    const [courseAttempData, setCourseAttempData] = useState()
    const [courseScoreData, setScoreAttempData] = useState()
    const [loader, setLoader] = useState(false);
    const barWidth = 20;
   // const [dataToDownload, setDataToDownload] = useState([]);
  

    const dataByFilter = {
        all: {
            labels: ['All'],
            datasets: [
                {
                    label: 'All',
                    data: [7.2],
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
                {
                    label: 'All',
                    data: [9.6],
                    backgroundColor: '#146B90',
                    barThickness: barWidth,
                },
            ],
        },
        entity: {
            labels: ['Finance', 'Technology', 'Admin'],
            datasets: [
                {
                    label: 'Entity Data',
                    data: [25, 15, 30],
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
            ],
        },
        department: {
            labels: ['Finance', 'Technology', 'Admin'],
            datasets: [
                {
                    label: 'Department Data',
                    data: [25, 15, 30],
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                    categoryPercentage: 1.1, // Adjust this value for spacing
                    barPercentage: 1.1, // Adjust this value for spacing
                },
            ],
        },
        age: {
            labels: ['18-30 yrs', '31-45 yrs', '46-65 yrs', '66-80 yrs', '80+yrs'],
            datasets: [
                {
                    label: 'Age',
                    data: [6.7, 7.7, 6.4, 8.2, 8.0],
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
                {
                    label: 'Age',
                    data: [9.6, 9.6, 9.6, 9.6, 9.6],
                    backgroundColor: '#146B90',
                    barThickness: barWidth,
                },
            ],
        },
        gender: {
            labels: ['Male', 'Female', 'Others'],
            datasets: [
                {
                    label: 'Gender',
                    data: [12, 18, 5],
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
            ],
        },
    };

    useEffect(()=> {
        coursePerformance(course_id)
        userCoursepScore(course_id)
        userCoursepAttempt(course_id)
        orgUserTestCard(course_id)
    },[])

    useEffect(()=> {
    },[activeFilter, course_id])


    const options2 = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const dataPoint = tooltipItem.raw; 
              const label = tooltipItem.label; 
              return ` ${label.charAt(0).toUpperCase() + label.slice(1)}` + ' : ' + `${dataPoint} ` + 'Users';
          },
          }
      },
        legend: {
          display: true,
          position: 'bottom'
        },
        datalabels: {
          color: 'white',
          anchor: "middle",
          align: "middle",
          font: {
            weight: 'bold'
          },
          formatter: (value) =>  {
            if(value === 0) {
                return ' ';
            }
              return value;
           } 
        }, 
      }
    };

    const options = {
      categoryPercentage: 2.1, // Adjust this value for spacing
      barPercentage: 2.1, // Adjust this value for spacing
      indexAxis: 'y',
      responsive: true,
      // hover: {
      //   animationDuration: 1
      // },
      // animation:{
      //   duration: 0,
      //   onComplete: function () {
      //     let myChart = this;
      //     let chartArea = myChart.chartArea;
      //     let { left, top } = chartArea;
      //     const datasets = myChart.data.datasets;
      //     for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
      //       const meta = myChart.getDatasetMeta(datasetIndex);
      //       const data = datasets[datasetIndex].data;
    
      //       for (let index = 0; index < data.length; index++) {
      //         let bar = meta.data[index];
      //         let value = data[index];
      //         if (value != 0) {
      //           let xPos = bar.x - barWidth;
      //           let yPos = bar.y; // Adjust yPos for horizontal bar chart
                
      //           if (xPos < left) {
      //             xPos = left + 1;
      //           }
  
      //           var ctx = myChart.ctx;
      //           ctx.save();
      //           ctx.textAlign = 'center';
      //           ctx.textBaseline = 'middle';
      //           ctx.fillStyle = 'black';
      //           ctx.font = 'bold 12px Arial';
      //           ctx.fillText(value, xPos, yPos);
      //           ctx.restore();
      //         } 
      //       }
      //     }
      //   }
      // },
      plugins: {
        // tooltip: {
        //   enabled: true,
        //   position: 'nearest',
        //   // external: customTooltipRenderer,
        // },
        legend: {
          display: false,
          position: 'bottom',
          borderRadius: 5
        },
        datalabels: {
          color: 'black',
          anchor: "end",
          align: "start",
          offset: (context) => {
            return context.dataset.data[context.dataIndex] === 0 ? -15 : 5; 
          },
          font: {
            weight: 'bold'
          },
          formatter: (value) =>  {
            if(value === 0) {
                return '0';
            }
              return value;
           } 
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: true,
          },
        },
        y: {
          display: true,
          grid: {
            display: false,
          },
        }
      },
    };

    const handleFilterClick = (filter) => {
      setActiveFilter((prevFilter) => {
        if (prevFilter !== filter) {
          coursePerformance(course_id, filter);
        }
        return filter;
      });
    };
    // apiService.getanalytics(`course-performance?org_id=${org_id||''}&course_id=${course_id || ''}&completion_percent=100&filter=${filter || ''}`,token)
    const coursePerformance=(course_id, filter)=>{
        setLoader(true)
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem("ORGID")
        apiService.getanalytics(`course-performance?org_id=${org_id||''}&course_id=${course_id || ''}&user_course_status=pass&analytics_category=course_performance&filter=${filter || ''}`,token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                 setCoursePerformanceDatas(response?.data, filter)
                 setCoursePerformances(response?.data)
                 setLoader(false)
            }
        }).catch((err)=> {
          console.log(err)
          setLoader(false)
        })
    }

    const userCoursepScore=(course_id)=>{
        setLoader(true)
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem("ORGID")
        apiService.getanalytics(`user-course-score?org_id=${org_id||''}&course_id=${course_id || ''}&user_course_status=pass&test_type=end_line`,token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setEndlineScoreDatas(response?.data)
                setUCourseScore(response?.data)
                setLoader(false)
            }
        }).catch((err)=> {
          setLoader(false)
          console.log(err)
        }) 
    }

    const userCoursepAttempt=(course_id)=>{
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem("ORGID")
        apiService.getanalytics(`course-attempt-performance?org_id=${org_id||''}&course_id=${course_id || ''}&user_course_status=pass&test_type=end_line`,token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setEndlineAttemptDatas(response?.data)
                setUserCoursepAttempt(response?.data)
            }
        }) 
    }

    const  orgUserTestCard=(course_id)=>{
        const token = apiService.getAuthToken()
        const org_id = localStorage.getItem("ORGID")
        apiService.getanalytics(`user-test-report?org_id=${org_id||''}&course_id=${course_id || ''}&user_course_status=pass`,token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setOrgUserTestCardData(response?.data)
            }
        }) 
    }
    
    const setCoursePerformanceDatas1 = (coursePerformanceProps, filter) => {
        coursePerformanceProps = Array.isArray(coursePerformanceProps) ? coursePerformanceProps : typeof coursePerformanceProps == 'object' ? [coursePerformanceProps] : []
        if(coursePerformanceProps && coursePerformanceProps.length > 0) {
          if(filter == 'all') {
            const labels = coursePerformanceProps && coursePerformanceProps?.map(course => course?.course_name)
            const baselineData = coursePerformanceProps?.map(course => {
              if(course?.tests_result) {
                const data = course?.tests_result.map(ele => {
                  if(ele.test_type == "base_line") {
                    return ele?.average_marks.toFixed(1)
                  }
                }).filter(ele => ele !=null || ele !=undefined)
                return data[0]
              }
            }).filter(ele => ele !=null || ele !=undefined)
            const endLineData = coursePerformanceProps?.map(course => {
              if(course?.tests_result) {
                const data =  course?.tests_result.map(ele => {
                  if(ele.test_type == "end_line") {
                    return ele?.average_marks.toFixed(1)
                  }
                }).filter(ele => ele !=null || ele !=undefined)
                return data[0]
              }
            }).filter(ele => ele !=null || ele !=undefined)
            const basline = {
              label: 'BaseLine',
              data: baselineData,
              backgroundColor: ['#D9E8EE'],
              barThickness: barWidth,
            }
            const endLine = {
              label: 'EndLine',
              data:  endLineData,
              backgroundColor: ['#146B90'],
              barThickness: barWidth,
            }
            const mData = {
              labels: labels,
              datasets: [basline, endLine]
            };
            setCoursePerformanceData(mData);
          } 
          else if(filter == 'department') {
            const departmentDatas = coursePerformanceProps[0];
          const labels = Object.keys(departmentDatas).filter(key => key !== null &&  key !== "NULL" && key !== "" && key !== "null");
          const finalLabels = []

          let endLineData = [];
          let baseLineData = []
          for (const label of labels) {
            const departmentData = departmentDatas[label];
            if (departmentData && departmentData.length > 0) {
              let hasEndLine = false;
              let hasBaseLine = false;
          
              for (const data of departmentData) {
                if (data.test_type === "end_line") {
                  endLineData.push(data.average_marks);
                  hasEndLine = true;
                } else if (data.test_type === "base_line") {
                  baseLineData.push(data.average_marks);
                  hasBaseLine = true;
                }
              }
          
              if (!hasEndLine) {
                endLineData.push(0);
              }
              if (!hasBaseLine) {
                baseLineData.push(0);
              }
            } else {
              endLineData.push(0);
              baseLineData.push(0);
            }
          }
          const mData = {
            labels: labels,
            datasets: [
              {
                label: 'baseline',
                data: baseLineData,
                backgroundColor: '#D9E8EE',
                barThickness: barWidth,
              },
              {
                label: 'endline',
                data: endLineData,
                backgroundColor: '#146B90',
                barThickness: barWidth,
              },
            ]
          };
            setCoursePerformanceData(mData);
          } else if(filter == 'gender') {
            const genderDatas = coursePerformanceProps[0];
            const labels = Object.keys(genderDatas).filter(key => key != null &&  key != "NULL" && key != ""  && key != "null")
            // const labels = Object.keys(genderDatas).filter(key => genderDatas[key].length > 0)
            let endLineData = [];
            let baseLineData = [];
            for (const label of labels) {
              const genderData = genderDatas[label];
              if (genderData && genderData.length > 0) {
                let hasEndLine = false;
                let hasBaseLine = false;
            
                for (const data of genderData) {
                  if (data.test_type === "end_line") {
                    endLineData.push(data.average_marks);
                    hasEndLine = true;
                  } else if (data.test_type === "base_line") {
                    baseLineData.push(data.average_marks);
                    hasBaseLine = true;
                  }
                }
            
                if (!hasEndLine) {
                  endLineData.push(0);
                }
                if (!hasBaseLine) {
                  baseLineData.push(0);
                }
              } else {
                endLineData.push(0);
                baseLineData.push(0);
              }
            }
            const mData = {
                labels: labels,
                datasets: [
                  {
                      label: 'baseline',
                      data: baseLineData,
                      backgroundColor: '#D9E8EE',
                      barThickness: barWidth,
                  },
                  {
                      label: 'endline',
                      data: endLineData,
                      backgroundColor: '#146B90',
                      barThickness: barWidth,
                  },
              ]
            };
            setCoursePerformanceData(mData);
          } else if(filter == 'age_group') {
            const ageGroupDatas = coursePerformanceProps[0];
            const labels = Object.keys(ageGroupDatas).filter(key => key != null &&  key != "NULL" && key != "" && key != "null")
            // const labels = Object.keys(ageGroupDatas).filter(key => ageGroupDatas[key].length > 0)
            let endLineData = [];
            let baseLineData = [];
            for (const label of labels) {
              const ageGroupData = ageGroupDatas[label];
              if (ageGroupData && ageGroupData.length > 0) {
                let hasEndLine = false;
                let hasBaseLine = false;
            
                for (const data of ageGroupData) {
                  if (data.test_type === "end_line") {
                    endLineData.push(data.average_marks);
                    hasEndLine = true;
                  } else if (data.test_type === "base_line") {
                    baseLineData.push(data.average_marks);
                    hasBaseLine = true;
                  }
                }
            
                if (!hasEndLine) {
                  endLineData.push(0);
                }
                if (!hasBaseLine) {
                  baseLineData.push(0);
                }
              } else {
                endLineData.push(0);
                baseLineData.push(0);
              }
            }
            
            const mData = {
                labels: labels,
                datasets: [
                  {
                      label: 'baseline',
                      data: baseLineData,
                      backgroundColor: '#D9E8EE',
                      barThickness: barWidth,
                  },
                  {
                      label: 'endline',
                      data: endLineData,
                      backgroundColor: '#146B90',
                      barThickness: barWidth,
                  },
              ]
            };
            setCoursePerformanceData(mData);
          } 
          else {
            const labels = coursePerformanceProps && coursePerformanceProps?.map(course => course?.course_name)
            const baselineData = coursePerformanceProps?.map(course => {
              if(course?.tests_result) {
                const data =  course?.tests_result.map(ele => {
                  if(ele.test_type == "base_line") {
                    return ele?.average_marks
                  }
                }).filter(ele => ele !=null || ele !=undefined)
                return data[0]
              }
            }).filter(ele => ele !=null || ele !=undefined)
            const endLineData = coursePerformanceProps?.map(course => {
              if(course?.tests_result) {
                const data = course?.tests_result.map(ele => {
                  if(ele.test_type == "end_line") {
                    return ele?.average_marks
                  }
                }).filter(ele => ele !=null || ele !=undefined)
                return data[0]
              }
            }).filter(ele => ele !=null || ele !=undefined)
            const basline = {
              label: 'BaseLine',
              data: baselineData,
              backgroundColor: ['#D9E8EE'],
              barThickness: barWidth,
            }
            const endLine = {
              label: 'EndLine',
              data:  endLineData,
              backgroundColor: ['#146B90'],
              barThickness: barWidth,
            }
            const mData = {
                labels: labels,
                datasets: [basline, endLine]
            };
            setCoursePerformanceData(mData)
          }
        } else{
          setCoursePerformanceData([])
        }
    }

    const setCoursePerformanceDatas = (coursePerformanceProps, filter) => {
      coursePerformanceProps = Array.isArray(coursePerformanceProps) ? coursePerformanceProps : typeof coursePerformanceProps == 'object' ? [coursePerformanceProps] : []
      if(coursePerformanceProps && coursePerformanceProps.length > 0) {
        if(filter == 'all') {
          const labels = coursePerformanceProps && coursePerformanceProps?.map(course => course?.course_name)
          const baselineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data = course?.tests_result.map(ele => {
                if(ele.test_type == "base_line") {
                  return Math.round(ele?.average_marks)
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const endLineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data =  course?.tests_result.map(ele => {
                if(ele.test_type == "end_line") {
                  return Math.round(ele?.average_marks)
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const basline = {
            label: 'BaseLine',
            data: baselineData,
            backgroundColor: ['#D9E8EE'],
            barThickness: barWidth,
          }
          const endLine = {
            label: 'EndLine',
            data:  endLineData,
            backgroundColor: ['#146B90'],
            barThickness: barWidth,
          }
          const mData = {
            labels: labels,
            datasets: [basline, endLine]
          };
          setCoursePerformanceData(mData);
        } 
        else if(filter == 'department') {
          const departmentDatas = coursePerformanceProps[0];
     //   const labels = Object.keys(departmentDatas).filter(key => key !== null &&  key !== "NULL" && key !== "" && key !== "null");
        const labels = Object.keys(departmentDatas).filter(
          key => key !== null && key !== "NULL" && key !== "" && key !== "null" && key !== "-");
        // remove the data
        const finalLabels = []

        let endLineData = [];
        let baseLineData = []
        for (const label of labels) {
          const departmentData = departmentDatas[label];
          if (departmentData && departmentData.length > 0) {
            let hasEndLine = false;
            let hasBaseLine = false;
        
            for (const data of departmentData) {
              if (data.test_type === "end_line") {
                endLineData.push(Math.round(data.average_marks));
                hasEndLine = true;
              } else if (data.test_type === "base_line") {
                baseLineData.push(Math.round(data.average_marks));
                hasBaseLine = true;
              }
            }
        
            if (!hasEndLine) {
              endLineData.push(0);
            }
            if (!hasBaseLine) {
              baseLineData.push(0);
            }
          } else {
            endLineData.push(0);
            baseLineData.push(0);
          }
        }
        const mData = {
          labels: labels,
          datasets: [
            {
              label: 'baseline',
              data: baseLineData,
              backgroundColor: '#D9E8EE',
              barThickness: barWidth,
            },
            {
              label: 'endline',
              data: endLineData,
              backgroundColor: '#146B90',
              barThickness: barWidth,
            },
          ]
        };
          setCoursePerformanceData(mData);
        } else if(filter == 'gender') {
          const genderDatas = coursePerformanceProps[0];
          const labels = Object.keys(genderDatas).filter(key => key != null &&  key != "NULL" && key != ""  && key != "null")
          // const labels = Object.keys(genderDatas).filter(key => genderDatas[key].length > 0)
          let endLineData = [];
          let baseLineData = [];
          for (const label of labels) {
            const genderData = genderDatas[label];
            if (genderData && genderData.length > 0) {
              let hasEndLine = false;
              let hasBaseLine = false;
          
              for (const data of genderData) {
                if (data.test_type === "end_line") {
                  endLineData.push(data.average_marks);
                  hasEndLine = true;
                } else if (data.test_type === "base_line") {
                  baseLineData.push(data.average_marks);
                  hasBaseLine = true;
                }
              }
          
              if (!hasEndLine) {
                endLineData.push(0);
              }
              if (!hasBaseLine) {
                baseLineData.push(0);
              }
            } else {
              endLineData.push(0);
              baseLineData.push(0);
            }
          }
          const mData = {
              labels: labels,
              datasets: [
                {
                    label: 'baseline',
                    data: baseLineData,
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
                {
                    label: 'endline',
                    data: endLineData,
                    backgroundColor: '#146B90',
                    barThickness: barWidth,
                },
            ]
          };
          setCoursePerformanceData(mData);
        } else if(filter == 'age_group') {
          const ageGroupDatas = coursePerformanceProps[0];
          const labels = Object.keys(ageGroupDatas).filter(key => key != null &&  key != "NULL" && key != "" && key != "null")
          // const labels = Object.keys(ageGroupDatas).filter(key => ageGroupDatas[key].length > 0)
          let endLineData = [];
          let baseLineData = [];
          for (const label of labels) {
            const ageGroupData = ageGroupDatas[label];
            if (ageGroupData && ageGroupData.length > 0) {
              let hasEndLine = false;
              let hasBaseLine = false;
          
              for (const data of ageGroupData) {
                if (data.test_type === "end_line") {
                  endLineData.push(data.average_marks);
                  hasEndLine = true;
                } else if (data.test_type === "base_line") {
                  baseLineData.push(data.average_marks);
                  hasBaseLine = true;
                }
              }
          
              if (!hasEndLine) {
                endLineData.push(0);
              }
              if (!hasBaseLine) {
                baseLineData.push(0);
              }
            } else {
              endLineData.push(0);
              baseLineData.push(0);
            }
          }
          
          const mData = {
              labels: labels,
              datasets: [
                {
                    label: 'baseline',
                    data: baseLineData,
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
                {
                    label: 'endline',
                    data: endLineData,
                    backgroundColor: '#146B90',
                    barThickness: barWidth,
                },
            ]
          };
          setCoursePerformanceData(mData);
        } else if(filter == 'location') {
          const locationDatas = coursePerformanceProps[0];
          const labels = Object.keys(locationDatas).filter(key => key != null &&  key != "NULL" && key != ""  && key != "null" && key !== "-")
          // const labels = Object.keys(genderDatas).filter(key => genderDatas[key].length > 0)
          let endLineData = [];
          let baseLineData = [];
          for (const label of labels) {
            const locationData = locationDatas[label];
            if (locationData && locationData.length > 0) {
              let hasEndLine = false;
              let hasBaseLine = false;
          
              for (const data of locationData) {
                if (data.test_type === "end_line") {
                  endLineData.push(Math.round(data.average_marks))
                  hasEndLine = true;
                } else if (data.test_type === "base_line") {
                  baseLineData.push(Math.round(data.average_marks))
                  hasBaseLine = true;
                }
              }
          
              if (!hasEndLine) {
                endLineData.push(0);
              }
              if (!hasBaseLine) {
                baseLineData.push(0);
              }
            } else {
              endLineData.push(0);
              baseLineData.push(0);
            }
          }
          const mData = {
              labels: labels,
              datasets: [
                {
                    label: 'baseline',
                    data: baseLineData,
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
                {
                    label: 'endline',
                    data: endLineData,
                    backgroundColor: '#146B90',
                    barThickness: barWidth,
                },
            ]
          };
          setCoursePerformanceData(mData);
        } else if(filter == 'org_entity') {
          const locationDatas = coursePerformanceProps[0];
          const labels = Object.keys(locationDatas).filter(key => key != null &&  key != "NULL" && key != ""  && key != "null")
          // const labels = Object.keys(genderDatas).filter(key => genderDatas[key].length > 0)
          let endLineData = [];
          let baseLineData = [];
          for (const label of labels) {
            const locationData = locationDatas[label];
            if (locationData && locationData.length > 0) {
              let hasEndLine = false;
              let hasBaseLine = false;
          
              for (const data of locationData) {
                if (data.test_type === "end_line") {
                  endLineData.push(Math.round(data.average_marks));
                  hasEndLine = true;
                } else if (data.test_type === "base_line") {
                  baseLineData.push(Math.round(data.average_marks));
                  hasBaseLine = true;
                }
              }
          
              if (!hasEndLine) {
                endLineData.push(0);
              }
              if (!hasBaseLine) {
                baseLineData.push(0);
              }
            } else {
              endLineData.push(0);
              baseLineData.push(0);
            }
          }
          const mData = {
              labels: labels,
              datasets: [
                {
                    label: 'baseline',
                    data: baseLineData,
                    backgroundColor: '#D9E8EE',
                    barThickness: barWidth,
                },
                {
                    label: 'endline',
                    data: endLineData,
                    backgroundColor: '#146B90',
                    barThickness: barWidth,
                },
            ]
          };
          setCoursePerformanceData(mData);
        }
        else {
          const labels = coursePerformanceProps && coursePerformanceProps?.map(course => course?.course_name)
          const baselineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data =  course?.tests_result.map(ele => {
                if(ele.test_type == "base_line") {
                  return Math.round(ele?.average_marks);
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const endLineData = coursePerformanceProps?.map(course => {
            if(course?.tests_result) {
              const data = course?.tests_result.map(ele => {
                if(ele.test_type == "end_line") {
                  return Math.round(ele?.average_marks);
                }
              }).filter(ele => ele !=null || ele !=undefined)
              return data[0]
            }
          }).filter(ele => ele !=null || ele !=undefined)
          const basline = {
            label: 'BaseLine',
            data: baselineData,
            backgroundColor: ['#D9E8EE'],
            barThickness: barWidth,
          }
          const endLine = {
            label: 'EndLine',
            data:  endLineData,
            backgroundColor: ['#146B90'],
            barThickness: barWidth,
          }
          const mData = {
              labels: labels,
              datasets: [basline, endLine]
          };
          setCoursePerformanceData(mData)
        }
      } else{
        setCoursePerformanceData([])
      }
    }

    const setEndlineAttemptDatas = (attemptsProps, filter) => {
      attemptsProps = Array.isArray(attemptsProps) ? attemptsProps : []
      if(attemptsProps && attemptsProps.length > 0) {
        const labels = Object.keys(attemptsProps[0])
          const datasets = [
            {
                label: 'Count',
                data: labels.map(section => attemptsProps.reduce((acc, obj) => acc + obj[section].users, 0)),
                backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
                barThickness: barWidth,
              }
          ]
          const mData = {
              labels: labels,
              datasets: datasets
          };
          setCourseAttempData(mData)
      }
    }

    const setEndlineScoreDatas = (attemptsProps, filter) => {
      attemptsProps = Array.isArray(attemptsProps) ? attemptsProps : []
      if(attemptsProps && attemptsProps.length > 0) {
        const labels = Object.keys(attemptsProps[0])
          const datasets = [
            {
                label: 'Count',
                data: labels.map(section => attemptsProps.reduce((acc, obj) => acc + obj[section], 0)),
                backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
                barThickness: barWidth,
              }
          ]
          const mData = {
              labels: labels,
              datasets: datasets
          };
          setScoreAttempData(mData)
      }
    }

    const colorCode = ["#146B90", "#F4B532", "#F59390", "#22A298"];


    const downloadDetailsReport = async (courseDetails) => {
      setLoader(true);
      let user_details = localStorage.getItem('user_details')
      const org_id = localStorage.getItem("ORGID")
      const token = apiService.getAuthToken()
      await apiService.get(`get-jet-admin-course-report?org_id=${org_id}&org_course_id=${org_course_id}`, token).then((data) => {
          if (data?.status == "success" || data?.success == true) {
              window.open(data?.data, "_blank")
          }
          setLoader(false);
      }).catch(error => {
        console.log(error);
        setLoader(false);
      })
  }

    return (
        <div className='page__main'>
            <div className="dashboard jetadmin">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><span className='fw-bold font-26 mb-2'>{course_name}</span></li>
                        <li className="breadcrumb-item fw-bold font-26 mb-2" aria-current="page">Stats</li>
                    </ol>
                </nav>

                <div className='w-50 d-flex justify-content-start mb-5'>
                    <div className='mx-2 p-3 col block bg_primary'>
                        <h2 className='d-flex justify-content-between text-white fw-bold'>{orgUserTestCardData?.passed_users}</h2>
                        <h3 className='font-18 text-white mb-0'>Course<br /> Passed</h3>
                    </div>
                    <div className='mx-2 p-3 col block bg_red'>
                        <h2 className='d-flex justify-content-between text-white fw-bold'>{orgUserTestCardData?.failed_users}</h2>
                        <h3 className='font-18 text-white mb-0'>Course<br /> Failed</h3>
                    </div>
                    <div className='mx-2 p-3 col block bg_pink'>
                        <h2 className='d-flex justify-content-between text-white fw-bold'>{orgUserTestCardData?.flagged_users}</h2>
                        <h3 className='font-18 text-white mb-0'>Course<br /> Flagged</h3>
                    </div>
                </div>
               {loader ? <Loader /> :
               ( 
               <div className="w-100">
                <div className='d-flex justify-content-between align-items-center w-100'>
                     <h3 className='fw-bold font-20 my-4'><span className='text-decoration-none text-black'>Course Performance</span></h3>
                    {/* <CSVLink
                   // data={[columns, ...dataToDownload]}
                   // filename={`data.csv`}
                  //</div> <button className='btn btn-md btn-secondary px-3 d-flex justify-content-between align-items-center '><img src='/images/excel-report-icon.svg' alt="excel-report" className='me-2' width={22} height={22} />Download User Wise Report</button>
                  //</div> </CSVLink>
               */}
                    <button className='btn btn-md btn-secondary px-3 d-flex justify-content-between align-items-center ' onClick={() => downloadDetailsReport()}><img src='/images/excel-report-icon.svg' alt="excel-report" className='me-2' width={22} height={22} />Download User Wise Report</button>
                </div>
                
                    <hr />
                    <div className='d-flex justify-content-between '>
                        <div className='filters mb-4'>
                            <button className='btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('all')}
                                style={{ color: activeFilter === 'all' ? '#146B90' : '#000000' }}
                            >
                                All
                            </button>
                            <button className='btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('org_entity')}
                                style={{ color: activeFilter === 'org_entity' ? '#146B90' : '#000000' }}
                            >
                                Entity
                            </button>
                            <button className='btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('department')}
                                style={{ color: activeFilter === 'department' ? '#146B90' : '#000000' }}
                            >
                                Department
                            </button>
                            <button className='btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('age_group')}
                                style={{ color: activeFilter === 'age_group' ? '#146B90' : '#000000' }}
                            >
                                Age
                            </button>
                            <button className='btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('gender')}
                                style={{ color: activeFilter === 'gender' ? '#146B90' : '#000000' }}
                            >
                                Gender
                            </button>
                            <button className='btn btn-link text-decoration-none me-2'
                                onClick={() => handleFilterClick('location')}
                                style={{ color: activeFilter === 'location' ? '#146B90' : '#000000' }}
                            >
                                Location
                            </button>
                        </div>

                        {/* <div className='text-end mb-4'><a href='/' className='btn btn-link export'>Export User Wise Data</a></div> */}
                    </div>
                <div className='statistics bg-white p-3 '>
                  <h3 className='fw-bold font-20 my-2 pb-4'>Baseline VS Endline</h3>
                  <div style={{ height: '400px', overflowY: 'auto' }}>
                    <div>
                      <Bar data={{
                        labels: coursePerformanceData?.labels || [],
                        datasets: coursePerformanceData?.datasets || [],
                      }} options={options} width={1000} plugins={[ChartDataLabels]} 
                        height={coursePerformanceData?.labels?.length > 5 ? coursePerformanceData?.labels?.length * 45 : 270}
                      />
                    </div>
                  </div>
                </div>
                   
            {coursePerformances && coursePerformances.length > 0 && activeFilter == '' || activeFilter == 'all' && coursePerformances?.map((course, index) => (
              <div className='statistics bg-white p-3 mt-4'>  <div key={index}>
                <h3 className='statistics text-capitalize p-3 fw-bold font-20 radius-0'>Shift In Understanding</h3>
                <div className='statistics bg-white p-3 mt-4 d-flex flex-wrap'>

                  <div className="col-3 mb-2 text-center">
                    {course?.tests_result?.map((testResult, i) => (
                      <>
                        {testResult.test_type === 'base_line' && (
                          <>
                            <h2 className="fw-bold font-26">{testResult?.average_marks ? testResult?.average_marks?.toFixed(1) : 'N/A'}</h2>
                            <h3 className="fw-medium font-18 mt-2">Avg. Base Line</h3>
                          </>
                        )}

                      </>
                    ))}
                  </div>

                  <div className="col-3 mb-2 text-center">
                    {course?.tests_result?.map((testResult, i) => (
                      <>
                        {testResult.test_type === 'end_line' && (
                          <>
                            <h2 className="fw-bold font-26">{testResult?.average_marks ? testResult?.average_marks?.toFixed(1) : 'N/A'}</h2>
                            <h3 className="fw-medium font-18 mt-2">Avg. End Line</h3>
                          </>
                        )}

                      </>
                    ))}
                  </div>

                  <div className="col-3 mb-2 text-center">
                    {course?.tests_result?.map((testResult, i) => (
                      <>
                        {testResult.test_type === 'end_line' && (
                          <>
                            <h2 className="fw-bold font-26">{testResult?.average_attempts ? testResult?.average_attempts?.toFixed(1) : 'N/A'}</h2>
                            <h3 className="fw-medium font-18 mt-2">Avg. Attempts</h3>
                          </>
                        )}

                      </>
                    ))}
                  </div>




                  {/* 
                                        {testResult.test_type === 'end_line' && (
                                            <>
                                                <h2 className="fw-bold font-26">{testResult?.average_marks ? testResult?.average_marks?.toFixed(1) : 'N/A'}</h2>
                                                <h3 className="fw-medium font-18 mt-2">Avg. End Line</h3>
                                            </>
                                        )}



                                        {testResult.test_type == 'end_line' && (
                                            <>
                                                <h2 className="fw-bold font-26">{testResult?.average_attempts ? testResult?.average_attempts?.toFixed(1) : 'N/A'}</h2>
                                                <h3 className="fw-medium font-18 mt-2">Avg. Attempts</h3>
                                            </>
                                        )} */}


                  <div className="col-3 mb-2 text-center">
                    <h2 className="fw-bold font-26 text-green">{course?.change_percent ? course?.change_percent + '%' : 'N/A'}</h2>
                    <h3 className="fw-medium font-18 mt-2">Change</h3>
                  </div>
                  {/* ))} */}

                </div>
              </div></div>
            ))}
                    
                </div>)
               }
                <div className="stats d-flex justify-content-between align-items-center my-4">
                    {loader ? <Loader /> : courseScoreData && Object.keys(courseScoreData).length > 0? <div className="w-50 bg-white p-3 me-3">

                        <h3 className="fw-bold font-20 mb-4">Endline Score Breakup</h3>
                        <hr />
                        {console.log(courseScoreData)}
                       {courseScoreData ?  <Doughnut
                            data={courseScoreData}
                            options={options2} plugins={[ChartDataLabels]}
                        /> : null}
                    </div> : null}
                    {loader ? <Loader /> : courseAttempData  && Object.keys(courseAttempData).length > 0 ? <div className="w-50 bg-white p-3 ms-3">

                        <h3 className="fw-bold font-20 mb-4">Endline Attempts</h3>
                        <hr />
                        {console.log(courseAttempData)}
                        {courseAttempData ? <Doughnut
                            data={courseAttempData}
                            options={options2} plugins={[ChartDataLabels]}
                        /> : null}
                    </div> : null}
                </div>

            </div>
        </div>
    )

}

export default Stats;
