import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './dashboard.css';
import apiService from '../../../services/apiService';
import moment from 'moment';
import {Link, useLocation} from 'react-router-dom'
import CustomToast from '../../Toast'
import {CSVLink} from 'react-csv';

const LicenseDetails = ({ showDetails = true, licenseDataFromDasboard, selectedOrgId}) => {
    const location = useLocation()
    licenseDataFromDasboard = location.state?.licenseDataFromDasboard || licenseDataFromDasboard;
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [orgInfo, setOrgInfo] = useState()
    const [courseOptions, setCourseOptions] = useState([]);
    const [licenseDatas, setLicenseDatas] = useState([])
    const [licenseData, setLicenseData] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState();
    const barWidth = 40;
    const [dataToDownload, setDataToDownload] = useState([]);
    const columns = [ // Define column names
    "S. No.",
    "Licenses Bought",
    "Invitation Sent",
    "Invite Accepted",
    "In Progress",
    "Course Completed"
    ];

    // const mdata = {
    //     labels: ['Licenses Bought', 'Invitation Sent', 'Invite Accepted', 'In Progress', 'Course Completed'],
    //     datasets: [
    //         {
    //             label: 'Count',
    //             backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
    //             borderColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
    //             data: [
                   
    //             ],
    //             barThickness: 50,
    //             stack: 'stack1',
    //         },
    //     ],
    // };

    // setData(mdata)

    useEffect(() => {
        window.scrollTo(0, 0);
        
        setDatas(licenseDataFromDasboard)
        setLicenseDatas([licenseDataFromDasboard])
        setOrgInfo(selectedOrgId)
    }, [licenseDataFromDasboard])

    useEffect(() => {
        const formattedData = licenseDatas?.map((row, i) => [
          // Include necessary fields in the exported CSV
          (i+1),
          // row.date, // If you want to include the date
          row.license_bought,
          row.invitation_sent,
          row.accepted_count,
          row.inprogress_count,
          row.completed_count,
        ]);
        setDataToDownload(formattedData);
      }, [licenseDatas]);

    const setDatas = (licenseDataFromDasboard) => {
        const data = {
            labels: ['Licenses Bought', 'Invitation Sent', 'Invite Accepted', 'In Progress', 'Course Completed'],
            datasets: [
                {
                    label: 'Users',
                    backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
                    borderColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
                    data: [
                        licenseDataFromDasboard?.license_bought,
                        licenseDataFromDasboard?.invitation_sent,
                        licenseDataFromDasboard?.accepted_count,
                        licenseDataFromDasboard?.inprogress_count,
                        licenseDataFromDasboard?.completed_count,
                    ],
                    barThickness: 40,
                    stack: 'stack1',
                },
            ],
        };
        setData(data)
    }

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(start && end) {
          fetchLicensesData(selectedOrgId, start, end, null)
        }
      };
    

    useEffect(() => {
        fetchCourseData(selectedOrgId)
    }, []);

    const handleCourseSelect = (selectedOptions) => {
        setSelectedCourses(selectedOptions);
        if(startDate && endDate) {
            fetchLicensesData(null, startDate, endDate, selectedOptions.value)
        } else {
            fetchLicensesData(null, null, null, selectedOptions.value)
        }
    };

    // useEffect(() => {
    //     if (courseOptions.length > 0) {
    //         // Set the initial value to the first course in the list
    //         setSelectedCourses({
    //             value: courseOptions[0].course_id,
    //             label: courseOptions[0].course_title
    //         });
    //     }
    // }, [courseOptions]);

    const fetchCourseData  = () => {
        const token = apiService.getAuthToken()
        const org_id = orgInfo || selectedOrgId
        if(org_id) {
        apiService.get(`get_org_course_list/${org_id|| ''}`, token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setCourseOptions(response?.data)
            }
        }).catch((error)=> {
            CustomToast.info(error)
        })
      } else {
        apiService.getCms(`dropdownCourseList`, token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setCourseOptions(response?.data)
            }
        }).catch((error)=> {
            CustomToast.info(error)
        })
      }
    }

    const fetchLicensesData  = (orgId, start, end, course_id) => {
        // const token = apiService.getAuthToken()
        let formattedStartDate = start ? moment(start).format('YYYY-MM-DD') : undefined;
        let formattedEndDate = end ? moment(end).format('YYYY-MM-DD') : undefined;
        const org_id = orgInfo || selectedOrgId;
        const today = moment();
        const oneMonthAgo = moment(today).subtract(30, 'days');
        const defaultStartDate = moment(oneMonthAgo).format('YYYY-MM-DD');
        const defaultEndDate = moment(today).format('YYYY-MM-DD');
        const token = apiService.getAuthToken()
        apiService.getanalytics(`licenses?org_id=${org_id||''}&startDate=${formattedStartDate||''}&endDate=${formattedEndDate||''}&courseId=${course_id||''}`, token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setLicenseDatas(response?.data)
                setDatas(response?.data)
            }
        }) 
    }

    function createDiagonalPattern(color) {
        // create a 10x10 px canvas for the pattern's base shape
        const shape = document.createElement('canvas');
        shape.width = 10;
        shape.height = 10;
        const c = shape.getContext('2d');
    
        // draw the diagonal lines
        c.strokeStyle = color;
        c.beginPath();
        c.moveTo(2, 0);
        c.lineTo(10, 8);
        c.stroke();
        c.beginPath();
        c.moveTo(0, 8);
        c.lineTo(2, 10);
        c.stroke();
    
        // create the pattern from the shape
        return c.createPattern(shape, 'repeat');
      }

    const options = {
        indexAxis: 'y',
        responsive: true,
        hover: {
          animationDuration: 1
        },
        animation:{
          duration: 0,
          onComplete: function () {
            let myChart = this;
            let chartArea = myChart.chartArea;
            let { left, top } = chartArea;
            const datasets = myChart.data.datasets;
            for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
              const meta = myChart.getDatasetMeta(datasetIndex);
              const data = datasets[datasetIndex].data;
      
              for (let index = 0; index < data.length; index++) {
                let bar = meta.data[index];
                let value = data[index];
                if (value != 0) {
                  let xPos = bar.x - barWidth;
                  let yPos = bar.y; // Adjust yPos for horizontal bar chart
                  
                  if (xPos < left) {
                    xPos = left + 6;
                  }
    
                  var ctx = myChart.ctx;
                  ctx.save();
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  ctx.fillStyle = 'black';
                  ctx.font = 'bold 12px Arial';
                  ctx.fillText(value, xPos, yPos);
                  ctx.restore();
                } 
              }
            }
          }
        },
        plugins: {
          tooltip: {
            enabled: true,
            position: 'nearest',
            // external: customTooltipRenderer,
          },
          legend: {
            display: false,
            position: 'bottom',
            borderRadius: 5
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: true,
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
            },
          }
        },
      };

    const options2 = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: true,
                position: 'nearest',
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                display: true,
                grid: {
                    display: false,
                },
            },
            y: {
                display: true,
                grid: {
                    display: true,
                },
            },
        },
    };

    const clearDateRange = () => {
        setStartDate(null);
        setEndDate(null);
        fetchLicensesData(orgInfo, null, null, selectedCourses?.value)
      };

    return (
        <>
         <div className="page__main">
            <div className="dashboard">
            <div>
            <h3 className='fw-bold font-20 mb-4'>
                {/* <a href='/license_details' className='text-decoration-none text-black'>License Details</a> */}
                <Link
                 to="/license_details"
                 state={{ showDetails: false, licenseDataFromDasboard}}
                className='text-decoration-none text-black'>
                    License Details
                </Link>
            </h3>
            {showDetails && (
                <div className='d-flex justify-content-between'>
                    <div className='mb-4 w-75'>
                        
                        <div className='d-flex'>
                            <Select
                                className='w-25 me-4'
                                placeholder="Select Course"
                                value={selectedCourses}
                                options={courseOptions?.map((ele) => ({
                                    value: ele.course_id,
                                    label: ele.name || ele.course_title
                                 }))}
                                onChange={handleCourseSelect}
                            />

                            {/* <Select
                                options={licenseData}
                                isMulti
                                value={selectedCourses}
                                onChange={handleCourseSelect}
                                className='w-25'
                                placeholder="Select Licence Type"
                            /> */}
                        </div>
                    </div>
                  <dive className="d-flex justify-content-center align-items-start">
                    <DatePicker
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateChange}
                            maxDate={new Date()}
                            className='p-2 mb-4'
                            placeholderText="Select date range" 
                        />
                    <button onClick={clearDateRange} className="btn-clear btn btn-md btn-secondary font-16 p-2">Clear</button>
                    </dive>

                    {/* <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText='Select Date'
                            className='p-2 mb-4'
                            onChange={(update) => {
                                handleStartDateChange(update[0]);
                                handleEndDateChange(update[1]);
                            }}
                            withPortal
                        /> */}
                </div>
            )}
                {console.log("license data", data)}
                {data ? <div className='stats statistics bg-white p-4'><Bar type="bar" data={data} options={options} /></div>: null}

                {showDetails && (
                    <>

                <div className="text-end my-4">
                <CSVLink
                    data={[columns, ...dataToDownload]}
                    filename={`data.csv`}
                >
                    <a className="btn btn-link export">CSV Export</a>
                </CSVLink>
                </div>

                <table className="table table-striped table-responsive mt-5">
                    <thead className="thead-dark">
                        <tr>
                            <th className="text-center w-10">S. No.</th>
                            {/* <th>Date</th> */}
                            <th>Licenses Bought</th>
                            <th>Invitation Sent</th>
                            <th>Invite Accepted</th>
                            <th>In Progress</th>
                            <th>Course Completed</th>
                        </tr>
                    </thead>
                    <tbody>    
                    {licenseDatas.length > 0 &&  licenseDatas?.map((ele, index) => (
                            <tr key={index}>
                                <td align="center">{index + 1}</td>
                                {/* <td>{ele?.date}</td> */}
                                <td>{ele?.license_bought}</td>
                                <td>{ele?.invitation_sent}</td>
                                <td>{ele?.accepted_count}</td>
                                <td>{ele?.inprogress_count}</td>
                                <td>{ele?.completed_count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </>
                )}
            </div>
        </div>
        </div>
        </>
    );
};

export default LicenseDetails;