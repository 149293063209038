import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/shared/dashboardLayout";
import OrgDashboardLayout from "./components/shared/orgDashboardLayout";
import Dashboard from "./components/pages/dashboard/dashboard";
import Orgnisation from "./components/pages/organisation/organisation";
import CreateOrgnisation from "./components/pages/organisation/createOrganisation";
import PrivateLayout from "./components/shared/privateLayout";
import MyProfile from "./components/pages/myProfile/myProfile";
import OrganisationDetails from "./components/pages/organisation/organisationDetails";
import AssignCourseForm from "./components/pages/organisation/assignCourseForm";
import EditOrgnisation from "./components/pages/organisation/editOrganization";
import ActiveUsers from "./components/pages/dashboard/activeUsers";
import LicenseDetails from "./components/pages/dashboard/licenseDetails";
import CourseWiseDistribution from "./components/pages/dashboard/courseWiseDistribution";
import LicenseSoldUsed from "./components/pages/dashboard/licenseSoldUsed";
import CoursePerformance from "./components/pages/dashboard/coursePerformance";
import UserScores from "./components/pages/dashboard/userScores";
import Attempts from "./components/pages/dashboard/attempts";
import ManageUser from "./components/pages/organisation/manageUser";
import InviteToCourse from "./components/pages/organisation/inviteToCourse/inviteToCourse";
import OrganisationAdmin from "./components/pages/organisation/organisationAdmin/organisationAdmin";
import Stats from "./components/pages/organisation/stats";
import EditProfile from "./components/pages/organisation/editProfile";

export default function AppRoutes() {

    return (
        <Router>
            <Routes>

                <Route
                    path="/"
                    element={<DashboardLayout />}
                >                
                    <Route path='/' element={<Dashboard />}></Route>
                    <Route path='/organisation' element={<Orgnisation />}></Route>
                    <Route path='/active_users' element={<ActiveUsers />}></Route>
                    <Route path='/license_details' element={<LicenseDetails />}></Route>
                    <Route path='/coursewise_distribution' element={<CourseWiseDistribution />}></Route>
                    <Route path='/license_sold_used' element={<LicenseSoldUsed />}></Route>
                    <Route path='/course_performance' element={<CoursePerformance />}></Route>
                    <Route path='/user_scores' element={<UserScores/>}></Route>
                    <Route path='/attempts' element={<Attempts/>}></Route>
                    

                </Route>

                <Route path="/" element={<OrgDashboardLayout />}
                >
                    <Route path='/organisation_details' element={<OrganisationDetails />}></Route>
                    <Route path="/organisation_details/:org_id" element={<OrganisationDetails />}/>
                    <Route path="/stats/:course_id/:course_name/:org_course_id" element={<Stats />}/>
                    <Route path="/manage_users" element={<ManageUser />}/>
                    <Route path='/assign_new_course' element={<AssignCourseForm />}></Route>
                    <Route path= '/organisation_admin' element= {<OrganisationAdmin /> }></Route> 
                </Route>

                <Route
                    path="/"
                    element={<PrivateLayout />}
                >
                    <Route path='/create_organisation' element={<CreateOrgnisation />}></Route>
                    <Route path='/my_profile' element={<MyProfile />}></Route>
                    <Route path="/course_invite" element={<InviteToCourse />}/>
                    <Route path= '/manage_user/edit_profile' element= {<EditProfile /> }></Route>
                </Route>
            </Routes>
        </Router>
    )
}