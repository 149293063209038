import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import AssignCourseModal from '../modal/assignCourseModal';
import CustomSelect from '../customSelect/customSelect';
import apiService from '../../../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomToast from '../../Toast'
import Loader from '../../layouts/loader';

const AssignCourseForm = () => {
    const [orderCost, setOrderCost] = useState('');
    const [selectedCourseName, setSelectedCourseName] = useState('');
    const [courseTitle, setCourseTitle] = useState('')
    const [licences, setLicences] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [remark, setRemark] = useState('');
    const [assignCourseModalIsOpen, setAssignCourseModalIsOpen] = useState(false);
    const [selectedCourseLable, setSelectedCourseLable] = useState('')
    const [courseList, setCourseList] = useState([])
    const [orgDetail, setOrgDetails] = useState([])
    const [loader, setLoader] = useState(false)
    const [stateData, setStateData] = useState()
    const [assignCourseList, setAssignCourseList] = useState([{
        org_id: orgDetail?.org_id,
        course_id: selectedCourseName,
        no_license: licences,
        order_cost: orderCost,
        expiry_date: expiryDate,
        remark: remark,
        course_title: selectedCourseLable ? selectedCourseLable.trim() : ""
    }])
    const navigate = useNavigate();
    const {state} = useLocation();
    console.log("state value ", state)
    const data = state?.orgData || state.element || state.orgDataInfo
    useEffect(() => {
      window.scrollTo(0, 0);
      let userDetail = sessionStorage.getItem("userDetail")
      userDetail = JSON.parse(userDetail)
      if(data){
        setOrgDetails(data)
      }
      fetchCourseList();
    //   console.log(data);
    //   setAssignCourseList(data.)
    //   setSelectedCourseName(data.course_id)
    //   setLicences()
    //   setUserData(userDetail)
     
    }, []);
  
    const fetchCourseList = () => {
        const org_id = "6e607024-2477-4bc0-ba19-7dc17737659f"
      const token = apiService.getAuthToken()
      apiService.getCms(`dropdownCourseList`, token).then(
        (response) => {
            setCourseList(response.data);
        },
        (error) => {
          console.error("Error fetching data", error);
          CustomToast.error('Error occured while featching the course list')
        }
      );
    };



    const courseNameOptions = [
        { course_id: '432380bf-8bc5-4000-95c1-115f9c8e9d86', course_title: 'Safe For Work' },
        { course_id: 'ad73317d-decc-4112-bda0-c583c0460261', course_title: 'Posh' },
    ];

    const handleCourseNameChange = (value) => {
        setSelectedCourseName(value);
    };

    const openAssignCourseModal = () => {
        setAssignCourseModalIsOpen(true);
    };

    const closeAssignCourseModal = () => {
        setAssignCourseModalIsOpen(false);
    };

    const handleAddCourse = () => {
        // const data = location.state?.data;
        // console.log(data);
        // Add the current course details to the courseData state
        const newCourse = {
            org_id : orgDetail?.org_id,
            course_id: "",
            no_license: "",
            order_cost: "",
            expiry_date: "",
            remark: "",
            course_title: selectedCourseLable ? selectedCourseLable.trim() : ""
        };
        assignCourseList[0].org_id = orgDetail?.org_id
        setAssignCourseList([...assignCourseList, newCourse]);
        
        // Clear form fields or reset state values after adding the course
        // setSelectedCourseName('');
        // setLicences('');
        // setOrderCost('');
        // setExpiryDate('');
        // setRemark('');
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if(assignCourseList && assignCourseList.length > 0 && (!assignCourseList[0]?.course_id)) {
            CustomToast.info('Please select course.')
            setLoader(false)
        } else {
            setLoader(true)
            assignCourseList[0].org_id = orgDetail?.org_id
            // assignCourseList[0].course_title = selectedCourseLable
           
            const token = apiService.getAuthToken()
            apiService.postV1(`assigned-org-course`, assignCourseList, token)
            .then((response) => {
                if(response.status == 'success') {
                    setLoader(false)
                    CustomToast.success('Course assigned successfully.')
                    navigate("/organisation");
                }
            })
            .catch((error) => {
                console.log(error);
                setLoader(false)
                if(error && error.message && error.message.includes('\"no_license\" is not allowed to be empty')) {
                    CustomToast.info('No. of licences is not allowed to be empty.')
                  }  else if(error && error.message && error.message.includes('\"order_cost\" is not allowed to be empty')) {
                    CustomToast.info('Order cost is not allowed to be empty.')
                  } else if(error && error.message && error.message.includes('\"expiry_date\" is not allowed to be empty')) {
                    CustomToast.info('Expiry date is not allowed to be empty.')
                  } else if(error && error.message && error.message.includes('\"remark\" is not allowed to be empty')) {
                    CustomToast.info('Remark is not allowed to be empty.')
                  } else {
                    CustomToast.error('Error occured while assign course')
                }
               
            });
        }
        
    };

    return (
        <div className='page__main'>
            <div className="dashboard" style={{minHeight: '100vh'}}>
                {loader ? <Loader />: 
                   <div className='profile-form-wrapper w-100 m-auto'>
                   <div className='d-flex justify-content-between align-items-center mb-4'>
                       <h3 className='font-weight-600 font-26'>Assign Course</h3>
                   </div>
                  <div >
                   {assignCourseList && assignCourseList?.map((course, index) => 
                   {
                      return  <form key={index}>
                       <div className="card p-lg-3 p-0 mb-4">
                       <div className="card-body">
                               <div className='row mb-5'>
                                   {console.log(courseList)}
                                   <div className="col-lg-12 form-group mb-3">
                                       <label htmlFor={`courseNameSelect-${index}`} className='mb-2 fw-bold'>Course Name*</label>
                                       <CustomSelect
                                           id={`courseNameSelect-${index}`}
                                           name="selectedCourseName"
                                           placeholder="Select Course"
                                           value={course.course_id}
                                           label={selectedCourseLable}
                                           options={courseList.map((option) => ({
                                               value: option.course_id, // Use the ID as the value
                                               label: option.name || option.course_title, // Display the name as the label
                                           }))}
                                           onChange={(e,l) => {
                                               const updatedForms = [...assignCourseList];
                                               updatedForms[index].course_id = e;
                                               updatedForms[index].course_title = l;
                                               setAssignCourseList(updatedForms);
                                               setSelectedCourseLable(l)
                                           }}
                                           required >
                                       </CustomSelect>

                                   </div>
                               </div>
                               <div className='row mt-5'>
                                   <div className="col-lg-4 form-group mb-3">
                                       <label className='mb-2 fw-bold'>No. Of Licences*</label>
                                       <input
                                           type="text"
                                           className="form-control"
                                           placeholder='100'
                                           value={course.no_license}
                                           name="licences"
                                           onChange={(e) => {
                                               const updatedForms = [...assignCourseList];
                                               updatedForms[index].no_license = e.target.value;
                                               setAssignCourseList(updatedForms);
                                           }}
                                           required />
                                   </div>

                                   <div className="col-lg-4 form-group mb-3">
                                       <label className='mb-2 fw-bold'>Order Cost*</label>
                                       <input
                                           type="text"
                                           className="form-control"
                                           placeholder='₹10,000'
                                           value={course.order_cost}
                                           name="orderCost"
                                           onChange={(e) => {
                                               const updatedForms = [...assignCourseList];
                                               updatedForms[index].order_cost = e.target.value;
                                               setAssignCourseList(updatedForms);
                                           }}
                                           required />
                                   </div>

                                   <div className="col-lg-4 form-group mb-3">
                                       <label className='mb-2 fw-bold'>Expiry Date*</label>
                                       <input
                                           type="date"
                                           className="form-control"
                                           placeholder='DD/MM/YYYY'
                                           value={course.expiry_date}
                                           name="expiryDate"
                                           min={new Date().toISOString().split('T')[0]}
                                           onChange={(e) => {
                                               const updatedForms = [...assignCourseList];
                                               updatedForms[index].expiry_date = e.target.value;
                                               setAssignCourseList(updatedForms);
                                           }}
                                           required
                                       />
                                   </div>
                               </div>

                               <div className='row'>
                                   <div className="col-lg-12 form-group mb-3">
                                       <label className='mb-2 fw-bold'>Remark*</label>
                                       <textarea
                                           name={remark}
                                           placeholder="Remark"
                                           className="form-control"
                                           rows={3}
                                           cols={5}
                                           value={course.remark}
                                           onChange={(e) => {
                                               const updatedForms = [...assignCourseList];
                                               updatedForms[index].remark = e.target.value;
                                               setAssignCourseList(updatedForms);
                                           }}
                                           required
                                       />
                                   </div>
                               </div>
                           </div>
                       </div>
                     </form>
                   }
                       )}

                   </div>
                   <div className='d-flex mt-4'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                               <path fillRule="evenodd" clipRule="evenodd" d="M14.0842 8.73415C18.1691 4.64923 24.8301 4.64923 28.9335 8.75255C33.0184 12.8375 33.0184 19.4985 28.9335 23.5834C24.8301 27.6867 18.1691 27.6867 14.0842 23.6018C9.98089 19.4985 9.98089 12.8375 14.0842 8.73415ZM20.4324 10.3718L20.4508 15.1007L15.7035 15.1007V17.2352L20.4508 17.2352L20.4324 21.9641H22.5669L22.5853 17.2352H27.2958V15.1007H22.5853L22.5669 10.3718H20.4324Z" fill="#146B90" />
                           </svg>
                           <button onClick={openAssignCourseModal} className='fw-bold btn btn-link p-0 font-20 text-decoration-none primary_color ms-2' type="button">
                               Add More Courses
                           </button>
                           <div className='d-flex justify-content-end mt-4'>
                           <Modal
                               isOpen={assignCourseModalIsOpen}
                               onRequestClose={closeAssignCourseModal}
                               contentLabel="Assign Course Modal"
                           >
                               <AssignCourseModal  handleAddCourse={handleAddCourse} onRequestClose={closeAssignCourseModal}/>
                           </Modal>
                           </div>
                       </div>

                       <div className='d-flex justify-content-end mt-4'>
                           {/* Existing buttons and modal */}
                           <a href={`/organisation_details/${orgDetail?.org_id}`} className='btn btn-md btn-secondary w-25 p-2 me-2'>Cancel</a>
                           <button type='button'  onClick={handleSubmit} className='btn btn-primary w-25 p-2 ms-3'>Assign</button>
                       </div>
               </div>
                }
            </div>
        </div>
    );

}

export default AssignCourseForm;