import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import Navbar from '../layouts/navbar'
import Loader from '../layouts/loader';

const DashboardLayout = ({children}) => {
   // State to hold the token
   const [authToken, setAuthToken] = useState(null);
   const [loader, setLoader] = useState(true)


   useEffect(() => {
     // Retrieve the token from the URL parameters
     const urlParams = new URLSearchParams(window.location.search);
     const token = urlParams.get('ref');
 
     // Update the state with the token
     if (token) {
       let decodedRef = decodeURIComponent(token);
       decodedRef = JSON.parse(decodedRef)
       console.log("data", decodedRef)
       setAuthToken(decodedRef.token);
       // Save the token to local storage
       localStorage.setItem('authToken', decodedRef.token);
       sessionStorage.setItem("userDetail",JSON.stringify(decodedRef))
       window.history.replaceState({}, document.title, window.location.pathname);
       setLoader(false)
     } else {
      setLoader(false)
     }
   }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
    {loader ? <Loader/> :  <Navbar children={children}>
            <Outlet/>
        </Navbar>}
    </>
  )
}
export default DashboardLayout;