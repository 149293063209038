import http from "../httpCommon";

class OrganisationServices {
    getAllLicense(currentPage) {
      return http.get(`v1/org/get-org-info?page=${currentPage || 1}&itemsPerPage=10`);
    }

    createOrganisation(data) {
      return http.post("v1/org/create-org", data);
    }
  

}

export default new OrganisationServices();
