
import React from 'react';

const DeactivateUserModal = ({ isOpen, onRequestClose, onConfirm }) => {

    const closeActivateModal = () => {
        onRequestClose();
    };

    const handleConfirm = () => {
        // Call the onConfirm function passed as a prop
        onConfirm();
        // Close the modal
        closeActivateModal();
    };

    return (
        <form>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <img src='/images/active-user.svg' alt='revoke-user' />

                <h3 className='font-weight-600 font-20 my-4'>Deactivate  User</h3>
                <p>Are you sure you want to deactivate user?</p>
                <div className='d-flex justify-content-between align-items-center w-100 mt-3'>
                    <button onClick={closeActivateModal} className='btn btn-md w-100 btn-secondary me-3'>Cancel</button>
                    <button onClick={handleConfirm} className='btn btn-md w-100 btn-primary'>Confirm</button>
                </div>
            </div>
        </form>
    );
};

export default DeactivateUserModal;