import React,{useState, useEffect} from "react";
import Select from "react-select";
import { PieChart } from "react-chartkick";
import "chartkick/chart.js";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './dashboard.css';
import moment from 'moment';
import apiService from "../../../services/apiService";
import CustomToast from "../../Toast";
import {Link, useLocation} from 'react-router-dom'

const CourseWiseDistribution = ({ showDetails = true ,courseDistribution, selectedOrgId}) => {
  // console.log("course ",courseDistribution)
  // const [selectedOrgId, setSelectedOrgId] = useState()
  const location = useLocation()
  courseDistribution = location.state?.courseDistribution || courseDistribution;
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [courseDistributionInfo, setCourseDistributionInfo] = useState()
  const [orgInfo, setOrgInfo] = useState()
  // const newData = [
  //   ["Introduction To DIB", 52],
  //   ["Introduction To DIB", 4],
  //   ["Safe Workplace", 32],
  //   ["Safe Workplace", 12],
  //    ]

  const [newCourseData, setNewCourseData] = useState()

  // const activeData = [
  //   ["Introduction To DIB", 52],
  //   ["Introduction To DIB", 4],
  //   ["Safe Workplace", 32],
  //   ["Safe Workplace", 12],
  //    ]
  const [activeCourseData, setActiveCourseData] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);

    setCourseDistributionInfo(courseDistribution)
    setDatas(courseDistribution)
    setOrgInfo(selectedOrgId)
}, [courseDistribution])



const setDatas = (courseDistribution) => {
  const newCourse = courseDistribution?.newCourse?.map(course => [course?.course_title, Math.round(course?.license_bought_percentage)]);
  const activeCourse = courseDistribution?.activeCourse?.map(course => [course?.course_title, Math.round(course?.license_bought_percentage)]);

  // Sort the arrays based on the second element (percentage) in descending order
  newCourse?.sort((a, b) => b[1] - a[1]);
  activeCourse?.sort((a, b) => b[1] - a[1]);

  // Get the top 4 records
  const top4NewCourse = newCourse?.slice(0, 4);
  const top4ActiveCourse = activeCourse?.slice(0, 4);

  setNewCourseData(top4NewCourse);
  setActiveCourseData(top4ActiveCourse);
};



const soldCourseDistribution=(course_id, orgData, start, end)=>{
  let formattedStartDate = start ? moment(start).format('YYYY-MM-DD') : undefined;
  let formattedEndDate = end ? moment(end).format('YYYY-MM-DD') : undefined;
  const org_id = orgInfo || selectedOrgId
  const token = apiService.getAuthToken()
  apiService.getanalytics(`course-distribution?org_id=${org_id || ''}&startDate=${formattedStartDate || ''}&endDate=${formattedEndDate || ''}&courseId=${course_id || ''}`,token).then((response)=> {
      console.log("response", response)
      if(response?.data) {
        setDatas(response?.data)
        setCourseDistributionInfo(response?.data)
      }
  }) 
}

useEffect(() => {
  // if (selectedOrgId) {
  //   soldCourseDistribution(null, selectedOrgId)
  //   fetchCourseData(selectedOrgId)
  // } {
  //   fetchCourseData()
  // }
  fetchCourseData()
}, [])

const handleCourseSelect = (selectedOptions) => {
  setSelectedCourses(selectedOptions);
  if (startDate && endDate) {
    soldCourseDistribution(selectedOptions.value,  selectedOrgId, startDate, endDate)
  } else {
    soldCourseDistribution(selectedOptions.value, selectedOrgId)
  }
};

const fetchCourseData  = (orgData) => {
  const token = apiService.getAuthToken()
  const org_id = orgInfo || selectedOrgId
  if(org_id) {
  apiService.get(`get_org_course_list/${org_id|| ''}`, token).then((response)=> {
      console.log("response", response)
      if(response?.data) {
          setCourseOptions(response?.data)
      }
  }).catch((error)=> {
      CustomToast.info(error)
  })
} else {
  apiService.getCms(`dropdownCourseList`, token).then((response)=> {
      console.log("response", response)
      if(response?.data) {
          setCourseOptions(response?.data)
      }
  }).catch((error)=> {
      CustomToast.info(error)
  })
}
}

// useEffect(() => {
//   if (courseOptions.length > 0) {
//       // Set the initial value to the first course in the list
//       setSelectedCourses({
//           value: courseOptions[0].course_id,
//           label: courseOptions[0].course_title
//       });
//   }
// }, [courseOptions]);


const handleDateChange = (dates) => {
  const [start, end] = dates;
  setStartDate(start);
  setEndDate(end);
  if (start && end) {
    soldCourseDistribution(null, selectedOrgId, start, end)
  }
};

const clearDateRange = () => {
  setStartDate(null);
  setEndDate(null);
  soldCourseDistribution(selectedCourses?.value, orgInfo, null, null)
};


const top4NewCourse = courseDistributionInfo?.newCourse
  ?.map(course => [course?.course_title, Math.round(course?.license_bought_percentage)])
  .sort((a, b) => b[1] - a[1]) // Sort in descending order
  .slice(0, 4); // Get the top 4 records

  const top4ActiveCourse = courseDistributionInfo?.activeCourse
  ?.map(course => [course?.course_title, Math.round(course?.license_bought_percentage)])
  .sort((a, b) => b[1] - a[1]) // Sort in descending order
  .slice(0, 4); // Get the top 4 records  


const colorCode = ["#146B90", "#F4B532", "#F59390", "#22A298", "#146B45", "#F4B234", "#F59354", "#22A213"];

const options = {
  responsive: true,
  plugins: {
      tooltip: {
          enabled: false,
          position: 'nearest',
      },
      legend: {
          display: false,
      },
      datalabels: {
        anchor: "middle",
        align: "top",
        color: '#fff',
        font: {
           weight: '600'
         },
        formatter: (value) =>  {
         if(value === 0) {
             return ' ';
         }
           return value + '%';
        } 
      },
  },
  scales: {
      x: {
          display: true,
          grid: {
              display: false,
          },
      },
      y: {
          display: true,
          grid: {
              display: true,
          },
      },
  },
};


  return (
    <>
      <div className="page__main">
        <div className="dashboard">
        <h3 className="fw-bold font-20 mb-4">
        <Link
                 to="/coursewise_distribution"
                 state={{ showDetails: false, courseDistribution}}
                className='text-decoration-none text-capitalize text-black'>
                    Course wise distribution
                </Link>
          </h3>
       
        {showDetails && (
          <div className='d-flex justify-content-between'>
            <div className='mb-4 w-75'>
             
              <div className='d-flex'>
              <Select
                                   options={courseOptions?.map((ele) => ({
                                    value: ele.course_id,
                                    label: ele.course_title || ele.name
                                    }))}
                                    value={selectedCourses}
                                    onChange={handleCourseSelect}
                                    className='w-25 me-4'
                                    maxDate={new Date()}
                                    placeholder="Select Course"
                                />

                {/* <Select
                  options={courseOptions}
                  isMulti
                  value={selectedCourses}
                  onChange={handleCourseSelect}
                  className='w-25'
                  placeholder="Select Licence Type"
                /> */}

              </div>
            </div>
          <div className="d-flex justify-content-center align-items-start">
            <DatePicker
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateChange}
                            className='p-2 mb-4'
                            maxDate={new Date()}
                            placeholderText="Select date range"
                        />
                        <button onClick={clearDateRange} className="btn-clear btn btn-md btn-secondary font-16 p-2">Clear</button>
                        </div>
          </div>
          )}
          <div className=" d-flex justify-content-between align-items-center">
            <div className="w-50 bg-white p-3 me-3">
              <h3 className="fw-bold font-20 mb-4">New Courses</h3>
              <hr />

              <PieChart
                legend={false}
                donut={true}
                colors={colorCode}
                data={newCourseData}
                options={options}
                plugins={[ChartDataLabels]}
              />

              <div className='statistics p-3 my-4 d-flex flex-wrap'>
                {console.log("distributed course data", courseDistributionInfo)}

                {top4NewCourse &&
                  Array.isArray(top4NewCourse) &&
                  top4NewCourse.map((ele, i) => (
                    <div className="col-6 mb-2 text-center" key={i}>
                      <h2 className="font-20 mb-2" style={{ color: colorCode[i] }}>{ele[1]}</h2>
                      <h3 className="font-18 text-capitalize" style={{ color: colorCode[i] }}>{ele[0]}</h3>
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-50 bg-white p-3 ms-3">
              <h3 className="fw-bold font-20 mb-4">Active Courses</h3>
              <hr />
              {activeCourseData ? <PieChart
                legend={false}
                donut={true}
                colors={colorCode}
                data={activeCourseData}
                options={options}
                plugins={[ChartDataLabels]}
              /> : null}
             <div className='statistics p-3 my-4 d-flex flex-wrap'>
             {top4ActiveCourse &&
                  top4ActiveCourse &&
                  Array.isArray(top4ActiveCourse) &&
                  top4ActiveCourse?.map((ele, i) => (
                    <div className="col-6 mb-2 text-center" key={i}>
                      <h2 className="font-20 mb-2" style={{ color: colorCode[i] }}>{ele[1]}</h2>
                      <h3 className="font-18 text-capitalize" style={{ color: colorCode[i] }}>{ele[0]}</h3>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* {showDetails && (
             <div className='text-end my-4'><a href='/' className='btn btn-link export'>CSV Export</a></div>
          )} */}
        </div>
      </div>
    </>
  );
}

export default CourseWiseDistribution;