import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './dashboard.css';
import apiService from '../../../services/apiService';
import moment from 'moment';
import { useLocation, Link} from 'react-router-dom';
import CustomToast from '../../Toast';
import {CSVLink} from 'react-csv';

const UserScores = ({ showDetails = true, userScoresProps, selectedOrgId}) => {
  const location = useLocation()
  userScoresProps = location.state?.userScoresProps || userScoresProps;
  selectedOrgId = location.state?.selectedOrgId || selectedOrgId;
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [orgInfo, setOrgInfo] = useState()
  const [courseOptions, setCourseOptions] = useState([]);
  const [uCourseScore, setUCourseScore] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState()
  const [activeFilter, setActiveFilter] = useState('all');
  const [data, setData] = useState();
  const barWidth = 40;
  const [dataToDownload, setDataToDownload] = useState([]);
  const columns = [ // Define column names
      "S. No.",
      "Scores",
      "No Of Users"
    ];

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if(userScoresProps) {
      setDatas(userScoresProps);
      setUCourseScore(userScoresProps)
    }
  }, [userScoresProps, activeFilter])

  useEffect(() => {
    const formattedData = uCourseScore && uCourseScore.length > 0 && activeFilter === 'all'  ? Object.entries(uCourseScore[0])?.map(([key, value], i) => [
      // Include necessary fields in the exported CSV
      (i+1),
      // row.date, // If you want to include the date
      value,
      (i+1)
    ]) : [];
    setDataToDownload(formattedData);
  }, [uCourseScore]);
 
  const setDatas = (userScoresProps, filter) => {
    userScoresProps = Array.isArray(userScoresProps) ? userScoresProps : []
    // userScoresProps = Array.isArray(userScoresProps) ? coursePerformanceProps : typeof coursePerformanceProps == 'object' ? [coursePerformanceProps] : []
    if(userScoresProps && userScoresProps.length > 0) {
      if(filter == 'all') {
        const labels = Object.keys(userScoresProps[0]); // Assuming the sections are keys in the response
        const datasets = [
          {
            label: 'Count',
            data: labels.map(section => userScoresProps.reduce((acc, obj) => acc + obj[section], 0)),
            backgroundColor: '#146B90',
            barThickness: 40,
          },
        ];
        const mData = {
          labels: labels,
          datasets: datasets,
        };
        setData(mData);
      } else if(filter == 'department') {
        userScoresProps = userScoresProps.filter(ele => ele?.department !== null && ele?.department !== undefined && ele?.department !== "undefined");
        const labels = userScoresProps && userScoresProps?.map(ele => ele?.department)
        const datasets = userScoresProps &&  userScoresProps?.map(ele => ({
          label: 'Department',
          data: [Math.round(ele?.avg_endline_score)],
          backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
          barThickness: 40,
        }))
        const mData = {
            labels: labels,
            datasets: datasets
        };
          setData(mData)
      } else if(filter == 'gender') {
        userScoresProps = userScoresProps.filter(ele => ele?.gender !== null && ele?.gender !== undefined && ele?.gender !== "undefined");
        const labels = userScoresProps && userScoresProps?.map(ele => ele?.gender)
        const datasets =  userScoresProps && userScoresProps?.map(ele => ({
          label: 'Gender',
          data: [Math.round(ele?.avg_endline_score)],
          backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
          barThickness: 40,
        }))
        const mData = {
            labels: labels,
            datasets: datasets
        };
          setData(mData)
      } else if(filter == 'age_group') {
        const labels = userScoresProps && userScoresProps?.map(ele => ele?.age_group)
        const datasets =  userScoresProps && userScoresProps?.map(ele => ({
          label: 'Age Group',
          data: [Math.round(ele?.avg_endline_score)],
          backgroundColor: ['#146B90', '#ED483D', '#F4B532', '#F59390', '#22A298'],
          barThickness: 40,
        }))

        const mData = {
          labels: labels,
          datasets: datasets
        };

        setData(mData);
      } else {
        const labels = Object.keys(userScoresProps[0]); // Assuming the sections are keys in the response
        const datasets = [
          {
            label: 'Count',
            data: labels.map(section => userScoresProps.reduce((acc, obj) => acc + obj[section], 0)),
            backgroundColor: '#146B90',
            barThickness: 40,
          },
        ];

        const mData = {
          labels: labels,
          datasets: datasets,
        };

        setData(mData);
      }
    }
  }
 
const userCoursepScore=(course_id, orgId, start, end, filter)=>{
  let formattedStartDate = start ? moment(start).format('YYYY-MM-DD') : undefined;
  let formattedEndDate = end ? moment(end).format('YYYY-MM-DD') : undefined;
  const org_id = orgInfo || selectedOrgId
  const today = moment();
  const oneMonthAgo = moment(today).subtract(30, 'days');
  const defaultStartDate = moment(oneMonthAgo).format('YYYY-MM-DD');
  const defaultEndDate = moment(today).format('YYYY-MM-DD');
  const token = apiService.getAuthToken()
  apiService.getanalytics(`user-course-score?org_id=${org_id||''}&startDate=${formattedStartDate || ''}&endDate=${formattedEndDate || ''}&filter=${filter || ''}&analytics_category=course_score`,token).then((response)=> {
      console.log("response", response)
      if(response?.data) {
         if(filter !="gender" || filter !="age_group" || filter !="department")
          setDatas(response?.data, filter)
          setUCourseScore(response?.data)
      }
  }).catch((error)=> {
      CustomToast.error('Something went wrong')
  })
}

const handleFilterClick = (filter) => {
  setActiveFilter((prevFilter) => {
    if (prevFilter !== filter) {
      // setData()
      userCoursepScore(null, orgInfo, null, null, filter);
    }
    return filter;
  });
};

const options2 = {
  responsive: true,
  plugins: {
      tooltip: {
          enabled: true,
          position: 'average',
      },
      legend: {
          display: true,
      },
  },
  scales: {
      x: {
          display: true,
          grid: {
              display: false,
          },
      },
      y: {
          display: true,
          grid: {
              display: true,
          },
      },
  },
};

const options = {
  indexAxis: 'y',
  responsive: true,
  hover: {
    animationDuration: 1
  },
  animation:{
    duration: 0,
    onComplete: function () {
      let myChart = this;
      let chartArea = myChart.chartArea;
      let { left, top } = chartArea;
      const datasets = myChart.data.datasets;
      for (let datasetIndex = 0; datasetIndex < datasets.length; datasetIndex++) {
        const meta = myChart.getDatasetMeta(datasetIndex);
        const data = datasets[datasetIndex].data;

        for (let index = 0; index < data.length; index++) {
          let bar = meta.data[index];
          let value = data[index];
          if (value != 0) {
            let xPos = bar.x - barWidth;
            let yPos = bar.y; // Adjust yPos for horizontal bar chart
            
            if (xPos < left) {
              xPos = left + 2;
            }

            var ctx = myChart.ctx;
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'white';
            ctx.font = 'bold 12px Arial';
            ctx.fillText(value, xPos, yPos);
            ctx.restore();
          } 
        }
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: true,
      position: 'nearest',
      // external: customTooltipRenderer,
    },
    legend: {
      display: false,
      position: 'bottom',
      borderRadius: 5
    }
  },
  scales: {
    x: {
      display: true,
      grid: {
        display: true,
      },
    },
    y: {
      display: true,
      grid: {
        display: false,
      },
    }
  },
};

const extractedNumberFromString = (inputString) => {
  const matchResult = inputString.match(/\d{1,2}/);
  if (matchResult !== null) {
    const extractedNumber = parseInt(matchResult[0], 10);
    return extractedNumber;
  } else {
    return 0
  }
};

  return (
    <div className="page__main">
      <div className="dashboard">
        <h3 className='fw-bold font-20 mb-4'>
          {/* <a href='/user_scores' className='text-decoration-none text-black'>User Scores</a> */}
          <Link
              to="/user_scores"
              state={{ showDetails: false, userScoresProps}}
              className='text-decoration-none text-black'>
                  User Scores
          </Link>
        </h3>
        {showDetails && (
          <>
            <div className='mb-4 w-75'>
              {/* <Select
                options={courseOptions?.map((ele) => ({
                  value: ele.course_id,
                  label: ele.course_title || ele.name
               }))}
                //isMulti
                value={selectedCourses}
                onChange={handleCourseSelect}
                className='w-25 me-4'
                placeholder="Select Course"
              /> */}
            </div>

            <div className='filters mb-4'>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('all')}
                style={{ color: activeFilter === 'all' ? '#146B90' : '#000000' }}
              >
                All
              </a>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('department')}
                style={{ color: activeFilter === 'department' ? '#146B90' : '#000000' }}
              >
                Department
              </a>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('age_group')}
                style={{ color: activeFilter === 'age_group' ? '#146B90' : '#000000' }}
              >
                Age
              </a>
              <a href="javascript:void(0)" className='btn btn-link text-decoration-none me-2'
                onClick={() => handleFilterClick('gender')}
                style={{ color: activeFilter === 'gender' ? '#146B90' : '#000000' }}
              >
                Gender
              </a>
            </div>
          </>
        )}
        {data && Object.keys(data).length > 0 ? <div className='statistics bg-white p-3'>  <Bar data={{
              labels: data?.labels,
              datasets: data?.datasets,
            }} options={options} /></div> : null}

        {showDetails && (
           <>

                <div className="text-end my-4">
                    <CSVLink
                        data={[columns, ...dataToDownload]}
                        filename={`data.csv`}
                    >
                        <a className="btn btn-link export">CSV Export</a>
                    </CSVLink>
                </div>
          <table className="table table-striped table-responsive mt-5">
            <thead className="thead-dark">
              <tr>
                <th className="text-center w-10">S. No.</th>
                <th>Scores</th>
                <th>No. Of Users</th>
              </tr>
            </thead>
            <tbody>
              {console.log("sasa", uCourseScore)}
              {Array.isArray(uCourseScore) ? uCourseScore && uCourseScore.length > 0 && activeFilter === 'all' && Object.entries(uCourseScore[0]).map(([key, value], i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{extractedNumberFromString(key)}</td>
                  <td>{value}</td>
                </tr>
              )) : typeof uCourseScore == 'object' ? (
                Object.values(uCourseScore).map((category, i) => (
                  Array.isArray(category) && category.length > 0 ? (
                    Object.entries(category[0]).map(([key, value], j) => (
                      <tr key={i * 100 + j}>
                        <td>{i * 100 + j + 1}</td>
                        <td>{extractedNumberFromString(key)}</td>
                        <td>{value}</td>
                      </tr>
                    ))
                  ) : null
                ))
              ): null
            }
              
            </tbody> 
          </table>
          </>
        )}
      </div>
    </div>
  );
};

export default UserScores;
