import React, { useState, useEffect } from 'react';
import apiService from '../../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import CustomToast from '../../../Toast';
import Select from 'react-select';
import Loader from '../../../layouts/loader';

const Orgnisation = () => {

    const [archiveOrgModalIsOpen, setArchiveOrgModalIsOpen] = useState(false);
    const [orgDetails, setOrgDetails] = useState('');
    const [userDetail, setUserData] = useState()
    const [isEditing, setIsEditing] = useState(false);
    const [loader, setLoader] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [orgDataInfo, setOrgData] = useState({});
    const [selectedCourse, setSelectedCourses] = useState('')
    const [orgID, setOrgID] = useState('')
    const navigate = useNavigate();


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const openArchiveOrgModal = () => {
        setArchiveOrgModalIsOpen(true);
    };

    function handleEditProfileClick(element) {
        navigate('/edit_org_profile', { state: { orgData: orgDetails } });
    }

    const closeArchiveOrgModal = () => {
        setArchiveOrgModalIsOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        let userDetail = sessionStorage.getItem("userDetail")
        userDetail = JSON.parse(userDetail)
        setUserData(userDetail)
        let orgId = localStorage.getItem("ORGID")
        setOrgID(orgId)
        fetchOrgDetails(orgId);
    }, []);

    const fetchOrgDetails = (orgId) => {
        const org_id = orgId
        const token = apiService.getAuthToken()
        apiService.getv1(`get-org-details/${org_id}`, token).then(
            (response) => {
                setOrgDetails(response.data);
                localStorage.setItem('orgDetails', JSON.stringify(response.data))
                setLoader(false);
            },
            (error) => {
                console.error("Error fetching data", error);
                setLoader(false);
                CustomToast.error('Error while fetcing org')
            }
        );
    };

    return (
        <>
          {/*
          <Select
                id="orgSizeSelect"
                name="selectedOrgSize"
                placeholder="Select course" >
           </Select>
         */}  

            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="fw-bold font-26 mb-0">Assigned Admins </h4>
                       {/*
                       <a href="javascript:void(0)" onClick={() => handleEditProfileClick()} className="d-flex justify-content-between align-items-center text-decoration-none mb-0">
                            <img src="/images/edit.png" className="edit_btn" />
                            <a className="fw-bold text-decoration-none primary_color font-20">Edit</a>
                        </a>
                      */} 
                    </div>
                  {loader ? <Loader />
                  :   <div className="d-flex">
                  <div className="admin-img w-25 d-flex justify-content-center align-items-center" style={{Width: '350px', Height: '350px', background: '#F0F0F0'}} >
                      <img src={orgDetails.org_image} className="img-fluid w-100" style={{maxWidth: '350px', maxHeight: '350px', objectFit: 'contain', background: '#F0F0F0'}} />
                  </div>
                  <div className="admin-info w-75 ps-4">
                      <div className="row">
                          <div className="col-md-3">
                              <p>Org. Name</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_name}</p>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-3">
                              <p>Org. Size</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_size}</p>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-3">
                              <p>Org. URL</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_url}</p>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-3">
                              <p>Sector / Industry</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_sector}</p>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-3">
                              <p>Org. Address</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_address}</p>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-3">
                              <p>City</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_city}</p>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-3">
                              <p>State</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_state}</p>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-3">
                              <p>Pin Code</p>
                          </div>
                          <div className="col-md-9">
                              <p className="fw-bold">: {orgDetails.org_pincode}</p>
                          </div>
                      </div>
                  </div>
              </div>

                  }

                </div>
            </div>
        </>
    )

}

export default Orgnisation;