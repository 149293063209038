import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../layouts/navbar'

const OrgDashboardLayout = ({children}) => {
  return (
    <>
        <Navbar children={children} isOrganisationDetails={true}>
            <Outlet/>
        </Navbar>
    </>
  )
}
export default  OrgDashboardLayout;