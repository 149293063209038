import React, { useEffect, useState } from 'react';
import './inviteToCourse.css';
import Select from 'react-select';
// import CustomSelect from '../../customSelect/customSelect';
import InviteSelect from '../../customSelect/inviteSelect';

const SafeWorkplace = ({ data }) => {
  // Render nothing if courseData is still null
  const [orgDetails, setOrgDetails] = useState();
  const [options, setOptions] = useState(data)
  const [selectedCourse, setSelectedCourse] = useState({});

  useEffect(() => {
    let orgData = localStorage.getItem("orgData")
    if (orgData) {
      orgData = JSON.parse(orgData)
      setOrgDetails(orgData)
    }
    const selectedCourseObj = localStorage.getItem('selectedCourseObj');
    if (selectedCourseObj) {
      setSelectedCourse(JSON.parse(selectedCourseObj));
    }
  }, []);

  // const handleCourseClick = (course) => {
  //   setSelectedCourse(course);
  //   localStorage.setItem('selectedCourseObj', JSON.stringify(course));
  // };

  const handleCourseSelect = (selectedOptions) => {
    const create_obj = {
      org_course_id: selectedOptions.value,
      course_id: selectedOptions.course_id,
      course_title: selectedOptions.label
    }
    setSelectedCourse(create_obj)
    localStorage.setItem("selectedCourse", JSON.stringify([create_obj]))
    localStorage.setItem('selectedCourseObj', JSON.stringify(create_obj))
  };

  return (
    <>
      {selectedCourse ?
        <div className='safe-workspace-wrapper custom-bg'>
          <div className='d-flex justify-content-center align-items-center py-2'>
            <p className='mb-0 font-16 text-white'>You are inviting for {orgDetails ? <b>{orgDetails?.org_name}</b> : null} for course</p>
            <div className="dropdown safe-workspace">
              {/*  <Select
                  id="orgSizeSelect"
                  name="selectedOrgSize"
                  placeholder="Select course"
                  value={selectedCourse?.org_course_id}
                  options={options.map((option) => ({
                      value: option.org_course_id,
                      label: option.name || option.course_title,
                      course_id: option.course_id
                  }))}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      borderBottom: '1px solid #ccc',
                      color: state.isSelected ? 'white' : 'black',
                      backgroundColor: state.isSelected ? '#007bff' : 'white',
                      padding: 10,
                    }),
                    control: (provided) => ({
                      ...provided,
                      border: '1px solid #ddd',
                    }),
                  }}
                  onChange={handleCourseSelect}>   
                </Select> */}

              <InviteSelect
                from="invite"
                id="orgSizeSelect"
                name="selectedOrgSize"
                placeholder="Select"
                value={selectedCourse?.course_title}
                options={options.map((option) => ({
                  value: option.org_course_id,
                  label: option.name || option.course_title,
                  course_id: option.course_id
                }))}
                onChange={handleCourseSelect}>
              </InviteSelect>
            </div>
          </div>
        </div> : null
      }
    </>
  );
};

export default SafeWorkplace;