import React, { useState } from 'react';
import Modal from 'react-modal';

const AssignOrgModal = ({ handleAddCourse, onRequestClose }) => {
  // No need to manage state for modal visibility here
  const openAssignCourseModel = () => {
    handleAddCourse();
    onRequestClose(); // Call the provided onRequestClose prop
  };

  return (
    <form>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <img src='/images/search-icon.svg' alt='search-icon' />
        <h3 className='font-weight-600 font-20 my-4'>Assign Courses</h3>
        <p>Are you sure you want to add more courses for your organization?</p>
        <div className='d-flex justify-content-between align-items-center w-100 mt-3'>
          <button onClick={onRequestClose} className='btn btn-md w-100 btn-secondary me-3'>
            Cancel
          </button>
          <button type='button' onClick={openAssignCourseModel} className='btn btn-md w-100 btn-primary'>
            Confirm
          </button>
        </div>
      </div>
    </form>
  );
};

export default AssignOrgModal;