import React, { useState, useEffect} from 'react';
import SafeWorkplace from "./safeWorkplace";
import InviteCourseForm from './inviteCourseForm';
import CsvFileUpload from './csvFileUpload';
import './inviteToCourse.css';

const InviteToCourse = () => {
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
      const fetchCourseData = async () => {
        const selectedCourse = localStorage.getItem('selectedCourse'); // Use consistent key
        const courseList = localStorage.getItem('inviteCourseList')
        // const courseData = selectedCourse ? JSON.parse(selectedCourse) : [];
        const courseData = courseList ? JSON.parse(courseList) : [];
        setCourseData(courseData);
      };
    
      fetchCourseData();
    }, []);

    return (
        <div>
            {courseData && courseData.length > 0 ? 
            <div className='nosidebar-page__main'>
                <SafeWorkplace data={courseData}/>
                <div className="dashboard">
                <div className='course-card-wrapper d-flex flex-lg-row flex-column justify-content-evenly'>
                <div className="card p-lg-3 p-0 mb-lg-0 mb-5">
                    <div className="card-body">
                        <div className='text-center'>
                            <h3 className='fw-bold font-26'>Invite to Course</h3>
                            <p>Invite users using their email address</p>
                        </div>
                        <InviteCourseForm />                    
                    </div>
                </div>

                <div className='seperation-part'></div>
                <div className="card p-lg-3 p-0 bulk-invite">
                    <div className="card-body">
                        <div className='text-center'>
                            <h3 className='fw-bold font-26'>For Bulk Invite</h3>
                            <p>Invite multiple users to the course at once</p>
                        </div>
                        <CsvFileUpload />
                        <div className='mt-4 content'>
                        <strong>Note:</strong>  
                        <ol className='mt-3'>
                            <li className='font-12'>Ensure that the number of invites sent does not exceed the remaining licenses available.</li>
                            <li className='font-12'>A license is assigned as soon as an invitation is sent to the user, however it can be revoked before the user accepts it.</li>
                        </ol>
                        </div>
                    </div>
                </div>

                </div>
                    
                </div>
            </div> : null
            }
        </div>
    )

}

export default InviteToCourse;