import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';

const SidebarLink = styled(Link)`
  display: flex;
  color: #949494;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 29px;
  text-decoration: none;
  font-size: 22px;
  margin-bottom: 40px;
 
  &:hover {
    // background: #F2EDE5;
    // border-left: 4px solid green;
    cursor: pointer;
  }
`;
 
const SidebarLabel = styled.span`
  margin-left: 8px;
`;
 
const DropdownLink = styled(Link)`
  // background: transparent;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #898989;
  font-size: 15px;
 
  &:hover {
    // background: green;
    cursor: pointer;
  }
`;
 
const Menu = ({ item }) => {
  const location = useLocation();
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => {
    setSubnav(!subnav);
  };

  return (
    <>
    
      <SidebarLink to={item.path} 
      onClick={item.subNav && showSubnav} className={showSubnav && location.pathname === item.path ? 'active' : ''}>
        <div>
          <img src={item.icon}></img>
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((subItem, index) => {
          return (
            <DropdownLink
              to={subItem.path}
              key={index}
              
            >
              <SidebarLabel className={location.pathname === subItem.linkpath ? 'sub_active' : ''}>{subItem.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};
 
export default Menu;