import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import apiService from '../../../services/apiService';
import CustomToast from '../../Toast';
import { useNavigate } from 'react-router-dom';

const ExtendsDateModal = ({ onRequestClose, orgInfo, courseArr }) => {
  const [extendDate, setExtendDate] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const navigate = useNavigate()

  const closeExtendsDateModal = () => {
    onRequestClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      org_course_id: selectedCourse?.value,
      org_id: orgInfo?.org_id,
      expiry_date: extendDate
    };
    apiService
      .putWithJsonV1('extend_course_expiry_date', payload)
      .then((res) => {
        if (res && res.status === 'success') {
          //  navigate(`/organisation_details/${orgInfo?.org_id}`)
          window.location.reload();
          CustomToast.success('Course expiry date updated successfully');
          closeExtendsDateModal();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCourseSelect = (selectedOption) => {
    setSelectedCourse(selectedOption);
  };

  // No dependency array needed for this cleanup-free useEffect
  useEffect(() => {
    if (courseArr && courseArr.length > 0) {
      const newList = courseArr.flatMap((ele) => ele?.course_status.map((e) => ({
        course_title: e.course_title,
        org_course_id: e.org_course_id,
        course_id: e.course_id
      })));

      if (newList.length > 0) {
        setCourseList(newList);

        // Consider selecting the first course or using a different logic based on your requirements
        setSelectedCourse({
          value: newList[0].org_course_id,
          label: newList[0].course_title
        });
      }
    }
  }, [courseArr]);

  return (
    <form onSubmit={handleSubmit}>
      <h3 className='fw-bold my-2 text-center font-26'>Extend Course Date</h3>
      <div className='d-flex flex-column justify-content-center align-items-start'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 my-4'>
          <div className="form-group w-75 mb-3">
            <label className='mb-2 fw-bold'>Select Course</label>
            <Select
              id="orgSizeSelect"
              name="selectedOrgSize"
              placeholder="Select course"
              value={selectedCourse}
              options={courseList.map((option) => ({
                value: option.org_course_id,
                label: option.course_title
              }))}
              onChange={handleCourseSelect}
            />
          </div>
          <div className="form-group w-75 mb-3">
            <label className='mb-2 fw-bold'>Extend Date</label>
            <input
              type="date"
              className="form-control w-100"
              placeholder='DD/MM/YYYY'
              value={extendDate}
              name="extendDate"
              onChange={(e) => setExtendDate(e.target.value)}
            />
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center w-100 mb-2'>
          <button onClick={closeExtendsDateModal} className='btn btn-md w-100 btn-secondary me-3'>Cancel</button>
          <button type="submit" className='btn btn-md w-100 btn-primary'>Submit</button>
        </div>
      </div>
    </form>
  );
};

export default ExtendsDateModal;