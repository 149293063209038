import React, {useState, useEffect} from 'react';
import AssignedCourses from './assignedCourses';
import LicencesHistory from './licencesHistory';
import Modal from 'react-modal';
import ArchiveOrgModal from '../modal/archiveOrgModal';
import apiService from '../../../services/apiService';
import EditOrgnisation from './editOrganization';
import { useParams } from 'react-router-dom';
import Loader from '../../layouts/loader';


const OrganisationDetails = () => {
    const [archiveOrgModalIsOpen, setArchiveOrgModalIsOpen] = useState(false);
    const [orgDetails, setOrgDetails] = useState('');
    const [userDetail, setUserData] = useState()
    const [isEditing, setIsEditing] = useState(false);
    const [loader, setLoader] = useState(false);
    const [licenseInfo, setLicenseInfo] = useState();
    const [selectedOrgId, setselectedOrgId] = useState('')
    const { org_id } = useParams();

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const openArchiveOrgModal = () => {
        setArchiveOrgModalIsOpen(true);
    };

    const closeArchiveOrgModal = () => {
        setArchiveOrgModalIsOpen(false);
    };
  
    useEffect(() => {
        window.scrollTo(0, 0);
      let userDetail = sessionStorage.getItem("userDetail")
      userDetail = JSON.parse(userDetail)
      if(org_id) {
        localStorage.setItem("ORGID", org_id)
      }
      const orgId = org_id || localStorage.getItem('ORGID')
      setLoader(true);
      setselectedOrgId(orgId)
      setUserData(userDetail)
      fetchOrgDetails(orgId);
      fetchLicense(orgId);
    }, []);
  
    const fetchLicense = (orgid) => {
      const token = apiService.getAuthToken()
      const org_id = orgid
      apiService.getv1(`get-license-history/${org_id}`, token).then(
        (response) => {
            setLicenseInfo(response.data);
        },
        (error) => {
          console.error("Error fetching data", error);
        }
      );
    };

    const fetchOrgDetails = (orgid) => {
      const org_id = orgid
      const token = apiService.getAuthToken()
      apiService.getv1(`get-org-details/${org_id}`, token).then(
        (response) => {
            setOrgDetails(response.data);
            const obj = {
                org_id: response?.data?.org_id,
                org_name: response?.data?.org_name
            }
            localStorage.setItem("orgData", JSON.stringify(obj))
            setLoader(false);
        },
        (error) => {
          setLoader(false);
          console.error("Error fetching data", error);
        }
      );
    };

    return (
        <div className='page__main'>
            <div className="dashboard" style={{minHeight: '100vh'}}>
                {isEditing && (
                  <EditOrgnisation orgData={orgDetails} onClose={() => setIsEditing(false)} />
                ) ||  <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="fw-bold font-26 mb-0">Org. Details </h4>
                        <div className='d-flex justify-content-end align-items-center'>
                        <a onClick={handleEditClick} className="d-flex justify-content-between align-items-center text-decoration-none mb-0">
                            <img src="/images/edit.png" className="edit_btn" />
                            <a className="fw-bold text-decoration-none primary_color font-20">Edit</a>
                        </a>
                        {/* <span className="d-flex align-items-center justify-content-center ps-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser4" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                <path d="M16.3337 21.668C17.0409 21.668 17.7192 21.9489 18.2193 22.449C18.7194 22.9491 19.0003 23.6274 19.0003 24.3346C19.0003 25.0419 18.7194 25.7202 18.2193 26.2203C17.7192 26.7204 17.0409 27.0013 16.3337 27.0013C15.6264 27.0013 14.9481 26.7204 14.448 26.2203C13.9479 25.7202 13.667 25.0419 13.667 24.3346C13.667 23.6274 13.9479 22.9491 14.448 22.449C14.9481 21.9489 15.6264 21.668 16.3337 21.668ZM16.3337 13.668C17.0409 13.668 17.7192 13.9489 18.2193 14.449C18.7194 14.9491 19.0003 15.6274 19.0003 16.3346C19.0003 17.0419 18.7194 17.7202 18.2193 18.2203C17.7192 18.7204 17.0409 19.0013 16.3337 19.0013C15.6264 19.0013 14.9481 18.7204 14.448 18.2203C13.9479 17.7202 13.667 17.0419 13.667 16.3346C13.667 15.6274 13.9479 14.9491 14.448 14.449C14.9481 13.9489 15.6264 13.668 16.3337 13.668ZM16.3337 5.66797C17.0409 5.66797 17.7192 5.94892 18.2193 6.44902C18.7194 6.94911 19.0003 7.62739 19.0003 8.33464C19.0003 9.04188 18.7194 9.72016 18.2193 10.2203C17.7192 10.7204 17.0409 11.0013 16.3337 11.0013C15.6264 11.0013 14.9481 10.7204 14.448 10.2203C13.9479 9.72016 13.667 9.04188 13.667 8.33464C13.667 7.62739 13.9479 6.94911 14.448 6.44902C14.9481 5.94892 15.6264 5.66797 16.3337 5.66797Z" fill="black" />
                            </svg>
                        </span>
                        <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser4">
                            <li>
                                <a className="dropdown-item" href="/">
                                Edit
                                </a>
                            </li>
                            <li>
                                <button className="dropdown-item">
                                Export Report
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={openArchiveOrgModal} >
                                Archive Organisation
                                </button>
                                
                                <Modal
                                    isOpen={archiveOrgModalIsOpen}
                                    onRequestClose={closeArchiveOrgModal}
                                    contentLabel="Assign Course Modal"
                                >
                                    <ArchiveOrgModal />
                                </Modal>
                            </li>
                        </ul> */}
                        </div>
                    </div>
                  {loader ? <Loader />
                  : <>
                    <div className="d-flex">
                        <div className="admin-img w-25 d-flex justify-content-center align-items-center" style={{maxWidth: '350px', maxHeight: '350px', background: '#F0F0F0'}}>
                            <img src={orgDetails.org_image} className="img-fluid w-100" style={{maxWidth: '350px', maxHeight: '350px', objectFit: 'contain', background: '#F0F0F0'}} />
                        </div>
                        <div className="admin-info w-75 ps-4">
                            <div className="row">
                                <div className="col-md-3">
                                    <p>Org. Name</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_name}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>Org. Size</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_size}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>Org. URL</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_url}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>Sector / Industry</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_sector}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>Org. Address</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_address}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>City</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_city}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>State</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_state}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>Pin Code</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.org_pincode}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <p>Entities</p>
                                </div>
                                <div className="col-md-9">
                                    <p className="fw-bold">: {orgDetails.entities}</p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='primary-contact mt-4 p-4'>
                        <p className='fw-bold text-capitalize'>Primary Contact : {orgDetails.contact_name}</p>
                        <div className='d-flex'>
                            <p className='mb-0'>Email : <span className='fw-bold me-5'>{orgDetails.contact_email}</span></p>
                            <p className='mb-0'>Designation : <span className='fw-bold me-5'>{orgDetails.contact_designation}</span></p>
                            <p className='mb-0'>Phone Number : <span className='fw-bold'>{orgDetails.contact_mobile}</span></p>
                        </div>
                    </div>
                  </>}
                </div>
            </div>}
               {orgDetails ? 
                <AssignedCourses orgData={orgDetails} /> : null
               }
                <LicencesHistory licenseInfo={licenseInfo}/>

            </div>
        </div>
    )

}

export default OrganisationDetails;