import React, { useState, useEffect} from 'react';
import { Line } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './dashboard.css';
import Select from 'react-select';
import apiService from '../../../services/apiService';
import moment from 'moment';
import { useLocation } from 'react-router-dom';


const ActiveUsers = ({ showDetails = true, activeUsersProps}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState()
    const [orgInfo, setOrgInfo] = useState()
    const [activeUsersData, setActiveUsersData] = useState([])
    const originalData = {
        labels: ["2024-02-07", "2024-02-08", "2024-02-09", "2024-02-10", "2024-02-11", "2024-02-12", "2024-02-13", "2024-02-14"],
        datasets: [
            {
                label: "MAU",
                data: [33, 53, 85, 41, 44, 65, 44, 34],
                fill: false,
                borderColor: "#F4B532"
            },
            {
                label: "DAU",
                data: [2, 3, 15, 20, 10, 26, 5, 12],
                fill: false,
                borderColor: "#22A298"
            }
        ]
    };
    const [data, setData] = useState(originalData)
    const filteredData = startDate && endDate
    ? {
        labels: originalData.labels,
        datasets: originalData.datasets.map((dataset) => ({
            ...dataset,
            data: originalData.labels.map((label, index) => {
            const date = new Date(label);
            if (date >= startDate && date <= endDate) {
                return dataset.data[index];
            }
            return null;
            })
        }))
        }
    : originalData;

    const options = {
          elements: {
              line: {
                  tension : 0.8 
              },
          },
          tooltip: {
            
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
              },
            },
            y: {
              display: true,
              grid: {
                display: false,
              },
            }
          },
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // soldUsedLicense()
        // console.log("dsdasa",soldUsedLicensesProps,soldUsedLicense());
        setDatas(activeUsersData)
    }, [activeUsersData])

    const setDatas = (activeUsersData) => {
        activeUsersData = Array.isArray(activeUsersData) ? activeUsersData : []
        const transformedData = {
            labels: activeUsersData.map((entry) => moment(entry?.date).format('YYYY-MM-DD')),
            datasets: [
              {
                label: "MAU",
                data: activeUsersData.map((entry) => entry.mau),
                fill: false,
                borderColor: "#F4B532",
              },
              {
                label: "DAU",
                data: activeUsersData.map((entry) => entry.dau),
                fill: false,
                borderColor: "#22A298",
              },
            ],
          };
        setData(transformedData)
    }

    const  activeUsers =(orgId, start, end)=>{
        let formattedStartDate = start ? moment(start).format('YYYY-MM-DD') : undefined;
        let formattedEndDate = end ? moment(end).format('YYYY-MM-DD') : undefined;
        const org_id = orgId || (selectedOrg && selectedOrg.org_id);
        const today = moment();
        const oneMonthAgo = moment(today).subtract(30, 'days');
        const defaultStartDate = moment(oneMonthAgo).format('YYYY-MM-DD');
        const defaultEndDate = moment(today).format('YYYY-MM-DD');
        const token = apiService.getAuthToken()
        apiService.getanalytics(`daily-active-users?startDate=${formattedStartDate || defaultStartDate }&endDate=${formattedEndDate || defaultEndDate}`,token).then((response)=> {
            console.log("response", response)
            if(response?.data) {
                setDatas(response?.data)
                setActiveUsersData(response?.data)
            }
        }) 
    }

    useEffect(() => {
        activeUsers()
        // setLicenseDatas([licenseDataFromDasboard])
        let orgData = localStorage.getItem("selectedOrg")
        if (orgData && orgData != 'undefined' && orgData != undefined) {
            if (!selectedOrg) {
                orgData = JSON.parse(orgData)
                setOrgInfo(orgData)
            }
        }
    }, [])

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            activeUsers(null, null, start, end)
        }
    };

    return (
        <div className="page__main"><div className="dashboard">
            <div >
                <div className='d-flex justify-content-between'>

                    <h3 className='fw-bold font-22 mb-4'><a href='/active_users' className='text-decoration-none text-black'>Active Users</a></h3>

                    {showDetails && (
                        <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText='Select Date'
                        className='p-2 mb-4'
                        onChange={(update) => {
                            handleDateChange(update);
                        }}
                        withPortal
                      />
                    )}

                </div>

                <div className='stats statistics bg-white p-4'>
                    <Line data={data} options={options} />
                </div>

                {showDetails && (
                    <div>
                       {/* <div className='text-end my-4'><a className='btn btn-link export'>CSV Export</a></div> */}

                        <table className="table table-striped table-responsive mt-5">
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center w-10">S. No.</th>
                                    <th>No. Of Attempts</th>
                                    <th>No. Of Users</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td align="center">1</td>
                                    <td>May 25, 2023</td>
                                    <td>6,432</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

            </div>
        </div>
        </div>
    );
};

export default ActiveUsers;

