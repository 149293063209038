import React, {useState} from 'react';
import Modal from 'react-modal';

const ArchiveOrgModal = () => {

    const [archiveOrgModalIsOpen, setArchiveOrgModalIsOpen] = useState(false);

    const closeArchiveOrgModal = () => {
        setArchiveOrgModalIsOpen(false);
    };

    return (

            <form>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img src='/images/search-icon.svg' alt='search-icon' />
                    <h3 className='font-weight-600 font-20 my-4'>Archive Organisation</h3>
                    <p>Are you sure you want to archive organisation?</p>
                    <div className='d-flex justify-content-between align-items-center w-100 mt-3'>
                        <button className='btn btn-md w-100 btn-secondary me-3'>Cancel</button>
                        <button onClick={closeArchiveOrgModal} className='btn btn-md w-100 btn-primary'>Confirm</button>
                    </div>
                </div>
            </form>
    )
}

export default ArchiveOrgModal;