import React, { useState, useEffect } from 'react';
import Routes from "./appRoutes";
import { ToastContainer } from 'react-toastify';

function App() {

  return (
    // Pass the token to Routes component
    <>
    <ToastContainer />
    <Routes/>
    </>
  );
}

export default App;