export default function authHeader() {
const user = localStorage.getItem('authToken')


if (user) {

    return { 
        token: user,
        "app-key": process.env.REACT_APP_API_KEY,
     }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
} else {
    return {
        "app-key": process.env.REACT_APP_API_KEY,
        "token": "DYc+lgAADYKTWIP3/i9bvxSqiHhvliUACHkZ42JjDTU=",
        'Content-Type': 'multipart/form-data',
    };
}
}



