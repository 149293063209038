import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../layouts/navbar'

const PrivateLayout = ({children}) => {
  return (
    <>
        <Navbar children={children} showSidebar={false}>
            <Outlet/>
        </Navbar>
    </>
  )
}

export default PrivateLayout;