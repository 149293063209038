import React, {useState} from 'react';
import Modal from 'react-modal';

const PushUserModal = ({isOpen, onRequestClose, onConfirm}) => {

    const closeInviteModal = () => {
        onRequestClose();
    };

    const handleConfirm = () => {
        // Call the onConfirm function passed as a prop
        onConfirm();
        // Close the modal
        closeInviteModal();
    };

    return (

            <form>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img src='/images/invite-sent.svg' alt='revoke-user' />
                    <h3 className='font-weight-600 font-20 my-4'>Complete Notify Sent</h3>
                    <p>Push to complete course mail has been sent to selected user.</p>
                    <div className='d-flex justify-content-between align-items-center w-100 mt-3'>
                        <button className='btn btn-md w-100 btn-secondary me-3' onClick={closeInviteModal}>Cancel</button>
                        <button onClick={handleConfirm} className='btn btn-md w-100 btn-primary'>Confirm</button>
                    </div>
                </div>
            </form>

    )
}

export default PushUserModal;