import React from 'react';
import Orgnisation from "./organisation";
import Admin from "./admin";

const OrganisationAdmin = () => {
    return (
        <div className='page__main'>
            <div className="dashboard" style={{minHeight: '100vh'}}>
                <Orgnisation />
                <div className='hori_line'></div>
                <Admin />
            </div>
        </div>
    )

}

export default OrganisationAdmin;