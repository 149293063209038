import React, { useState, useEffect } from 'react';
import Menu from "./menu";
import styled from "styled-components";
import Footer from "./footer";
import Modal from 'react-modal';
import apiService from '../../services/apiService';
import Select from 'react-select';
import CustomToast from '../Toast'

const Navbar = ({ children, isOrganisationDetails, showSidebar = true }) => {
    const [show, setShow] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [userData, setUserData] = useState(false);
    const [orgData, setOrgData] = useState([])
    const [error, setError] = useState('');
    const [showOrganisationDetails, setShowOrganisationDetails] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState()

    const fetchData = async () => {
        try {
            const token = apiService.getAuthToken()
            const result = await apiService.get('get-org-user-info', token);
            if(result && result.status == "success") {
                setUserData(result.data);
            }
        } catch (error) {
            setError('Error fetching user data');
            console.error(error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
        let orgData = localStorage.getItem('orgData')
        if(orgData) {
            orgData = JSON.parse(orgData)
            setOrgData([orgData])
        }
        const checkSession = async () => {
            try {
               const token = apiService.getAuthToken() 
              const response = await apiService.get('check-user-session', token)
            } catch (error) {
              console.error('Error checking session:', error);
              if (error?.message == 'Invalid token id!') {
                window.location.href = process.env.REACT_APP_LOGIN_URL
                // if(process.env.REACT_APP_ENV == "development") {
                //     window.location.href = `https://staging.inclusional.com`;
                //   } else {
                //     window.location.href = `https://site.inclusional.com`;
                // }
              }
            }
          };
          checkSession();
    }, []);

    useEffect(() => {
        if (orgData.length > 0) {
            // Set the initial value to the first course in the list
            setSelectedOrg({
                value: orgData[0].org_id,
                label: orgData[0].org_name,
            });
        }
    }, [orgData]);

    const openVideoModal = () => {
        setModalOpen(true);
    };

    const closeVideoModal = () => {
        setModalOpen(false);
    };

    const getInitials = (name) => {
        return name?.split(' ').map(word => word.charAt(0)).join(' ').toUpperCase() || '';
    };

    const showSideBar = () => {
        setShow(!show);
    }

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('authToken');
        localStorage.removeItem("selectedCourseObj")
        localStorage.removeItem("selectedCourse")
        localStorage.removeItem("orgData")
        sessionStorage.removeItem("userDetail")
        localStorage.removeItem("ORGID")
        localStorage.removeItem("inviteCourseList")
        localStorage.removeItem("image")
        localStorage.removeItem("inviteData")
        localStorage.removeItem("selectedLanguageInfo")
        window.location.href = process.env.REACT_APP_LOGIN_URL
        // if(process.env.REACT_APP_ENV == "development") {
        //     window.location.href = `https://staging.inclusional.com`;
        //   } else {
        //     window.location.href = `https://site.inclusional.com`;
        //   }
    };

    const SidebarData = [
        {
            title: "Dashboard",
            path: "/",
            icon: "/images/dashboard.svg"
        },
        {
            title: "Organisation",
            path: "/organisation",
            icon: "/images/setting.svg"
        }
    ];

    const OrgSidebarData = [
        {
            title: "Org. Dashboard",
            path: "/organisation_details",
            icon: "/images/dashboard.svg"
        },
        {
            title: "Org. Manage Users",
            path: "/manage_users",
            icon: "/images/user.svg"
        },
        {
            title: "Org. Admin",
            path: "/organisation_admin",
            icon: "/images/setting.svg"
        }
    ];

    const renderSidebarMenu = () => {
        if (isOrganisationDetails) {
            return (
                <>
                <div className='sidebar-menu'>
                    <p className='mb-4 back-link text-center'>
                        <a href='/organisation' className='font-16 text-decoration-none text-black'>  <span> <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                            <path d="M26 15.2778H11.0428L14.8217 11.4883L13.3333 10L7 16.3333L13.3333 22.6667L14.8217 21.1678L11.0428 17.3889H26V15.2778Z" fill="#146B90" />
                        </svg></span> Back to Org. Listing</a>
                    </p>

                    {OrgSidebarData.map((item, index) => (
                        <Menu item={item} key={index} />
                    ))}

                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeVideoModal}
                        contentLabel="Video Modal"
                        className='videoModal w-75'
                     >  <button type="button" className="close text-white" onClick={closeVideoModal}>×</button>
                       <div className='video-container'>
                          <video autoPlay className="w-100" controls controlsList="nodownload">
                            <source src="https://inclusional.s3.ap-south-1.amazonaws.com/project_dev/videos/2024-01-30 13:42:16-VID-20240123-WA0001.mp4" type="video/mp4"  />
                          </video>
                          </div>
                    </Modal>
                </div>
                  <div className='sticky-btn text-center me-4'>
                  <button onClick={openVideoModal} className='btn btn-primary p-2 w-100'>Watch Tutorial</button>
              </div>
              </>
            );
        } else {
            return (
                <div className='sidebar-menu'>
                    {SidebarData.map((item, index) => (
                        <Menu item={item} key={index} />
                    ))}
                </div>
            );
        }
    };

   useEffect(() => {
        const handleScroll = () => {
            const sidebarMenu = document.querySelector('.header');
    
            if (sidebarMenu) {
                if (window.scrollY > 0) {
                    sidebarMenu.classList.add('scrollable');
                } else {
                    sidebarMenu.classList.remove('scrollable');
                }
            }

            let footer = document.querySelector('.footer');
            let stickyButton = document.querySelector('.sticky-btn');

            if (stickyButton) {

                if (window.innerHeight - footer.getBoundingClientRect().top > 0) {
                    stickyButton.style.bottom = window.innerHeight - footer.getBoundingClientRect().top + 20 + 'px';
                } else {
                    stickyButton.style.bottom = '20px';
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <header className={`header ${show ? "add_body_padding" : ""}  `} id="admin-dash-header">
                <div className='d-flex justify-content-between align-items-center w-50 me-5'>
                    <a href='/'> <img src="/images/logo.svg" alt="logo" className="navbar_logo" /></a>
                    {/* <h1 className='font-16 fw-bold ms-4 mb-0 w-50'>{isOrganisationDetails ? orgData[0]?.org_name: ' '}</h1> */}
                    <div className='header_toggle'>
                        <i id="header-toggle"></i>
                    </div>
                </div>

                <div className="dropdown sidebar-profile d-flex align-items-center justify-content-center">
                    <div className="language-picker">
                        <form action="" className="language-picker__form">
                            <select
                                id="language-picker"
                                name="language-picker"
                                className='form-control custom-select'
                                value={selectedLanguage}
                                onChange={(e) => setSelectedLanguage(e.target.value)}>
                                <option lang="" value="english">ENG</option>
                            </select>
                        </form>
                    </div>
                    <p className='username mb-0 ms-3'>Hello, {userData.name}</p>
                    <span className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                    {userData && userData?.pic && userData?.pic != "null"  && userData?.pic != null ? (
                                    <img src={userData.pic} alt="avatar" className="avatar rounded-circle" />
                                ) : (
                                    <div className='initials font-28 text-white fw-bold text-uppercase'>{getInitials(userData?.name)}</div>
                                )}
                    </span>
                    <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                        <li>
                            <a className="dropdown-item" href="/my_profile">
                                My Profile
                            </a>
                        </li>
                        <li>
                            <button className='dropdown-item' onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </div>
            </header>

            <div className='wrapper'>
                {showSidebar && (
                    <aside className={`sidebar ${show ? "review" : ""} `} id="admin-dash-nav">
                        <div className='d-flex flex-column justify-content-between h-100'>
                            {renderSidebarMenu()}
                        </div>
                    </aside>
                )}

                <main className={` ${show ? "add_body_padding" : "main"} `}>
                    {children}
                </main>
            </div>

            <Footer></Footer>
        </>
    )
}

export default Navbar;
